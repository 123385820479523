export enum Emea {
    eu = "derivativeV2_EU",
    default = "derivativeV2",
}

export enum FileType {
    Geometry = "GeometryFile",
    Model = "ModelFile",
    Template = "ModelTemplateFile",
}

export enum ExternalSystem {
    Forge = "forge",
}

import { Box, createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useIntl } from "react-intl";
import MessageButton from "../../components/ControlPanel/MessageButton";
import PdfButton from "../../components/ControlPanel/PdfButton";
import RedirectButton from "../../components/ControlPanel/RedirectButton";
import messages from "./messages";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            overflowX: "auto",
            height: "100%",
            padding: theme.spacing(0.5),
        },
        box: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexFlow: "row nowrap",
            whiteSpace: "nowrap",
            justifyContent: "space-between",
            alignItems: "center",
        },
        formGroup: {
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "center",
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            "& label": {
                marginLeft: theme.spacing(0.5),
                marginRight: theme.spacing(1),
                "& span": {
                    fontSize: ".9rem",
                },
            },
        },
        input: {
            width: "2.4rem",
            marginLeft: theme.spacing(1),
        },
    }),
);

interface IIssuePanelProps {}

const IssuePanel: React.FC<IIssuePanelProps> = () => {
    const classes = useStyles();

    // translations
    const intl = useIntl();
    const transRedirectButton = intl.formatMessage({ ...messages.redirectButton });

    return (
        <Box className={classes.root}>
            <Box className={classes.box}>
                <div>
                    <RedirectButton title={transRedirectButton} path="/" />
                </div>
                <div>
                    <PdfButton />
                    <MessageButton />
                </div>
            </Box>
        </Box>
    );
};

export default IssuePanel;

import { onError } from "@iolabs/app";
import { DispatchAction } from "@iolabs/redux-utils";
import {
    Box,
    createStyles,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Tooltip,
    useTheme,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import {
    ComparedAttribute,
    ComparedValueFragment,
    ComparerValue,
    useCompareVersionsQuery,
} from "../../graphql/generated/graphql";
import FormatedValue from "../../packages/formatters/FormatedValue";
import { onCompareItemSelect, useFilters, useModelVariantIds, usePanelRepresent } from "../../redux/dashboard";
import globalMessages from "../App/messages";
import { ExternalSystem } from "../Viewer/types/type";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            width: "100%",
            maxWidth: "100%",
            overflowY: "auto",
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(2),
        },
        tableContainer: {
            maxHeight: "100%",
        },
        table: {
            height: "100%",
            width: "100%",
            "& thead": {
                "& td, th": {
                    paddingTop: theme.spacing(2),
                    paddingLeft: 0,
                    fontSize: "0.8rem",
                    textTransform: "uppercase",
                },
            },
            "& td, th": {
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                borderBottomColor: theme.palette.white.main,
                height: "24px",
                padding: "0 6px 0 6px",
                fontSize: "0.8rem",
            },
            "& td:last-child, th:last-child": {
                paddingRight: "6px",
                textAlign: "right",
            },
        },
        tableRow: {
            cursor: "pointer",
        },
        cellModel1: {
            backgroundColor: `${theme.palette.border[200]} !important`,
        },
        cellModel1Border: {
            backgroundColor: `${theme.palette.border[200]} !important`,
            borderBottomColor: `${theme.palette.border[200]} !important`,
        },
        cellModel2: {
            backgroundColor: `${theme.palette.border[100]} !important`,
        },
        cellModel2Border: {
            backgroundColor: `${theme.palette.border[100]} !important`,
            borderBottomColor: `${theme.palette.border[100]} !important`,
        },
        cellShould: {
            backgroundColor: `${theme.palette.primary[100]} !important`,
        },
        cellShouldBorder: {
            backgroundColor: `${theme.palette.primary[100]} !important`,
            borderBottomColor: `${theme.palette.primary[100]} !important`,
        },
        cellFirstSize: {
            [theme.breakpoints.up("lg")]: {
                width: "34.5%",
            },
        },
        cellModel1BorderSize: {
            [theme.breakpoints.up("lg")]: {
                width: "33%",
            },
            [theme.breakpoints.up("xl")]: {
                width: "30%",
            },
        },
        cellModel2BorderSize: {
            [theme.breakpoints.up("lg")]: {
                width: "33%",
            },
            [theme.breakpoints.up("xl")]: {
                width: "30%",
            },
        },
        cellLastSize: {
            width: "2rem",
        },

        cellFirstSizeFull: {
            [theme.breakpoints.up("xl")]: {
                width: "35%",
            },
        },
        cellModel1BorderSizeFull: {
            [theme.breakpoints.up("lg")]: {
                width: "20%",
            },
            [theme.breakpoints.up("xl")]: {
                width: "22%",
            },
        },
        cellShouldBorderSizeFull: {
            [theme.breakpoints.up("lg")]: {
                width: "21%",
            },
            [theme.breakpoints.up("xl")]: {
                width: "22%",
            },
        },
        cellModel2BorderSizeFull: {
            [theme.breakpoints.up("lg")]: {
                width: "20%",
            },
            [theme.breakpoints.up("xl")]: {
                width: "22%",
            },
        },
        cellLastSizeFull: {
            width: "2rem",
        },

        cellTitle: {
            fontWeight: "bold",
            paddingLeft: "0 !important",
        },
        tableRowPaper: {
            "& td:first-child, th:first-child": {
                width: "2rem",
                borderTopWidth: "1px",
                borderTopStyle: "solid",
                borderTopColor: theme.palette.grey["600"],
                borderLeftWidth: "1px",
                borderLeftStyle: "solid",
                borderLeftColor: theme.palette.grey["600"],
                borderBottomWidth: "1px",
                borderBottomStyle: "solid",
                borderBottomColor: theme.palette.grey["600"],
                borderTopLeftRadius: "4px",
                borderBottomLeftRadius: "4px",
            },
            "& td, th": {
                borderTopWidth: "1px",
                borderTopStyle: "solid",
                borderTopColor: theme.palette.grey["600"],
                borderBottomWidth: "1px",
                borderBottomStyle: "solid",
                borderBottomColor: theme.palette.grey["600"],
            },
            "& td:last-child, th:last-child": {
                borderTopWidth: "1px",
                borderTopStyle: "solid",
                borderTopColor: theme.palette.grey["600"],
                borderRightWidth: "1px",
                borderRightStyle: "solid",
                borderRightColor: theme.palette.grey["600"],
                borderBottomWidth: "1px",
                borderBottomStyle: "solid",
                borderBottomColor: theme.palette.grey["600"],
                borderTopRightRadius: "4px",
                borderBottomRightRadius: "4px",
            },
        },
        tableRowPaperBorder: {
            "& td:first-child, th:first-child": {
                borderBottomColor: theme.palette.white.main,
                borderBottomLeftRadius: 0,
            },
            "& td, th": {
                borderBottomColor: theme.palette.white.main,
            },
            "& td:last-child, th:last-child": {
                borderBottomColor: theme.palette.white.main,
                borderBottomRightRadius: 0,
            },
        },
        tableRowPaperExpanded: {
            "& td:first-child, th:first-child": {
                borderLeftWidth: "1px",
                borderLeftStyle: "solid",
                borderLeftColor: theme.palette.grey["600"],
            },
            "& td:last-child, th:last-child": {
                borderRightWidth: "1px",
                borderRightStyle: "solid",
                borderRightColor: theme.palette.grey["600"],
            },
        },
        tableRowPaperExpandedLast: {
            "& td:first-child, th:first-child": {
                borderLeftWidth: "1px",
                borderLeftStyle: "solid",
                borderLeftColor: theme.palette.grey["600"],
                borderBottomWidth: "1px",
                borderBottomStyle: "solid",
                borderBottomColor: theme.palette.grey["600"],
                borderBottomLeftRadius: "4px",
            },
            "& td, th": {
                borderBottomWidth: "1px",
                borderBottomStyle: "solid",
                borderBottomColor: theme.palette.grey["600"],
            },
            "& td:last-child, th:last-child": {
                borderRightWidth: "1px",
                borderRightStyle: "solid",
                borderRightColor: theme.palette.grey["600"],
                borderBottomWidth: "1px",
                borderBottomStyle: "solid",
                borderBottomColor: theme.palette.grey["600"],
                borderBottomRightRadius: "4px",
            },
        },
        tableRowEmpty: {
            "& td, th": {
                height: "10px",
            },
        },
        expandedButton: {
            paddingTop: "0 !important",
            paddingBottom: "0 !important",
        },
        ellipsis: {
            display: "inline-block",
            whiteSpace: "nowrap",
            width: "200px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginBottom: "-4px",
        },
        skeletonBox: {
            "& span": {
                width: "100%",
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
                borderRadius: theme.shape.borderRadius,
            },
        },
    }),
);

interface IDataTableProps {}

const DataTable: React.FC<IDataTableProps> = () => {
    const classes = useStyles();
    const theme = useTheme();
    const panelRepresent = usePanelRepresent();
    const [expanded, setExpanded] = useState<string[]>([]);
    const isExpanded = useCallback(id => (Array.isArray(expanded) ? expanded.indexOf(id) !== -1 : false), [expanded]);
    const dispatch = useDispatch<DispatchAction>();
    const filters = useFilters();

    // translations
    const intl = useIntl();
    const transLoadingDataError = intl.formatMessage({ ...globalMessages.loadingDataError });

    const versionsToCompare = useModelVariantIds();

    const { data, loading, error } = useCompareVersionsQuery({
        skip: versionsToCompare.length < 2,
        variables: {
            projectFileVersionViewables: versionsToCompare,
            filters: filters,
            externalSystemCode: ExternalSystem.Forge,
        },
    });

    const categories = data?.compareGroups?.categories;

    if (error) {
        dispatch(
            onError({
                errorMessage: transLoadingDataError,
            }),
        );
    }

    const handleExpand = (value: string, e: any) => {
        let newExpanded;
        if (expanded.indexOf(value) !== -1) {
            newExpanded = expanded.filter(id => id !== value);
        } else {
            newExpanded = [value, ...expanded];
        }
        setExpanded(newExpanded);
        e.stopPropagation();
    };

    const getViewerIds = (comparedAttribute: ComparerValue) => {
        return comparedAttribute.instances;
    };

    const getComparedValue = (attribute: ComparedAttribute, comparerValueCode: string): ComparerValue => {
        return attribute.comparedValues?.find(cv => cv?.code === comparerValueCode) as ComparerValue;
    };

    const handleRowClick = (attribute: any) => () => {
        var opts = {
            selectedIds: {
                // 1: getViewerIds(getComparedValue(attribute, versionsToCompare[0].toString())),
                // 2: getViewerIds(getComparedValue(attribute, versionsToCompare[1].toString())),
            },
        };

        versionsToCompare.forEach(versionToCompare => {
            opts.selectedIds[versionToCompare] = getViewerIds(getComparedValue(attribute, versionToCompare.toString()));
        });

        dispatch(onCompareItemSelect(opts));
    };

    const renderAttribute = (attribute: any, index: number, level: number, isLastRow: boolean, isOpen?: boolean) => {
        return (
            <React.Fragment key={index}>
                <TableRow
                    onClick={handleRowClick(attribute)}
                    key={attribute!.code}
                    className={clsx(classes.tableRow, {
                        [classes.tableRowPaperExpanded]: isOpen,
                        [classes.tableRowPaperExpandedLast]: isLastRow,
                    })}
                >
                    <TableCell>
                        <div className={classes.expandedButton}>
                            {attribute.children && attribute.children.length > 0 && (
                                <IconButton
                                    aria-label="collapse"
                                    size="small"
                                    onClick={e => handleExpand(attribute.code, e)}
                                >
                                    {isExpanded(attribute.code) ? (
                                        <KeyboardArrowDownIcon fontSize="inherit" />
                                    ) : (
                                        <KeyboardArrowRightIcon fontSize="inherit" />
                                    )}
                                </IconButton>
                            )}
                        </div>
                    </TableCell>
                    <TableCell component="th" scope="row" style={{ paddingLeft: theme.spacing(level) }}>
                        {attribute!.name}
                    </TableCell>
                    <TableCell align="left">
                        {attribute!.description && (
                            <Tooltip title={attribute!.description as string} placement="top-start">
                                <span className={classes.ellipsis}>{attribute!.description}</span>
                            </Tooltip>
                        )}
                    </TableCell>

                    <TableCell align="center" className={isLastRow ? classes.cellModel1 : classes.cellModel1Border}>
                        <FormatedValue
                            value={getComparedValue(attribute, versionsToCompare[0].toString())?.value}
                            attributeType={attribute!.attributeType!}
                        />
                    </TableCell>
                    {panelRepresent && (
                        <TableCell align="center" className={isLastRow ? classes.cellShould : classes.cellShouldBorder}>
                            <FormatedValue
                                value={getComparedValue(attribute, "soll")?.value}
                                attributeType={attribute!.attributeType!}
                            />
                        </TableCell>
                    )}
                    <TableCell align="center" className={isLastRow ? classes.cellModel2 : classes.cellModel2Border}>
                        <FormatedValue
                            value={getComparedValue(attribute, versionsToCompare[1].toString())?.value}
                            attributeType={attribute!.attributeType!}
                        />
                    </TableCell>

                    <TableCell />
                </TableRow>

                {isExpanded(attribute.code) && attribute.children && attribute.children.length > 0
                    ? attribute.children.map((row, index) => renderAttribute(row, index, level + 1, isLastRow, true))
                    : null}
            </React.Fragment>
        );
    };

    useEffect(() => {
        if (categories) {
            setExpanded(categories!.map(category => category!.name));
        }
    }, [categories, setExpanded]);

    return (
        <>
            <div className={classes.root}>
                <TableContainer className={classes.tableContainer}>
                    {loading || error ? (
                        <Box className={classes.skeletonBox}>
                            <Skeleton variant="rect" height={50} />
                            <Skeleton variant="rect" height={100} />
                            <Skeleton variant="rect" height={100} />
                            <Skeleton variant="rect" height={100} />
                        </Box>
                    ) : (
                        <Table stickyHeader size="small" aria-label="dashboard data table" className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: "2rem" }} />
                                    <TableCell
                                        align="left"
                                        colSpan={2}
                                        className={clsx(classes.cellFirstSize, {
                                            [classes.cellFirstSizeFull]: panelRepresent,
                                        })}
                                    >
                                        Kategorie
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        className={clsx(classes.cellModel1Border, classes.cellModel1BorderSize, {
                                            [classes.cellModel1BorderSizeFull]: panelRepresent,
                                        })}
                                    >
                                        Modell 1
                                    </TableCell>
                                    {panelRepresent && (
                                        <TableCell
                                            align="center"
                                            className={clsx(classes.cellShouldBorder, classes.cellShouldBorderSizeFull)}
                                        >
                                            Soll
                                        </TableCell>
                                    )}
                                    <TableCell
                                        align="center"
                                        className={clsx(classes.cellModel2Border, classes.cellModel2BorderSize, {
                                            [classes.cellModel2BorderSizeFull]: panelRepresent,
                                        })}
                                    >
                                        Modell 2
                                    </TableCell>
                                    <TableCell
                                        className={clsx(classes.cellLastSize, {
                                            [classes.cellLastSizeFull]: panelRepresent,
                                        })}
                                    />
                                </TableRow>
                            </TableHead>

                            {categories?.map((category, index) => (
                                <React.Fragment key={index}>
                                    <TableBody>
                                        <TableRow
                                            key={index}
                                            className={clsx(classes.tableRow, classes.tableRowPaper, {
                                                [classes.tableRowPaperBorder]: isExpanded(category!.name),
                                            })}
                                        >
                                            <TableCell>
                                                <div className={classes.expandedButton}>
                                                    <IconButton
                                                        aria-label="collapse"
                                                        size="small"
                                                        onClick={e => handleExpand(category!.name, e)}
                                                    >
                                                        {isExpanded(category!.name) ? (
                                                            <KeyboardArrowDownIcon fontSize="inherit" />
                                                        ) : (
                                                            <KeyboardArrowRightIcon fontSize="inherit" />
                                                        )}
                                                    </IconButton>
                                                </div>
                                            </TableCell>
                                            <TableCell align="left" colSpan={2} className={classes.cellTitle}>
                                                {category!.name}
                                            </TableCell>
                                            <TableCell
                                                className={
                                                    !isExpanded(category!.name)
                                                        ? classes.cellModel1
                                                        : classes.cellModel1Border
                                                }
                                            />
                                            {panelRepresent && (
                                                <TableCell
                                                    className={
                                                        !isExpanded(category!.name)
                                                            ? classes.cellShould
                                                            : classes.cellShouldBorder
                                                    }
                                                />
                                            )}
                                            <TableCell
                                                className={
                                                    !isExpanded(category!.name)
                                                        ? classes.cellModel2
                                                        : classes.cellModel2Border
                                                }
                                            />
                                            <TableCell />
                                        </TableRow>
                                    </TableBody>
                                    {isExpanded(category!.name) && (
                                        <TableBody>
                                            {category &&
                                                category!.attributes!.map((attribute, index: number) =>
                                                    renderAttribute(
                                                        attribute,
                                                        index,
                                                        0,
                                                        category!.attributes!.length - 1 === index,
                                                        true,
                                                    ),
                                                )}
                                        </TableBody>
                                    )}
                                    <TableBody>
                                        <TableRow className={classes.tableRowEmpty}>
                                            <TableCell />
                                            <TableCell align="left" colSpan={2} />
                                            <TableCell className={classes.cellModel1Border} />
                                            {panelRepresent && <TableCell className={classes.cellShouldBorder} />}
                                            <TableCell className={classes.cellModel2Border} />
                                            <TableCell />
                                        </TableRow>
                                    </TableBody>
                                </React.Fragment>
                            ))}
                        </Table>
                    )}
                </TableContainer>
            </div>
        </>
    );
};

export default DataTable;

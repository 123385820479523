import { AxiosResponse } from "axios";
import { ApiClient } from "@iolabs/api-client";
import config from "../../config/config";
import { IViewerProxyResponse } from "./types";

const forgeApi = new ApiClient("/forge/datamanagement", config.api.baseUrl);

export const viewerProxy = (
    token: string,
    urn: string,
    projectId: string,
    fetchLatest: boolean = true,
): Promise<IViewerProxyResponse> => {
    ApiClient.setAuthorizationToken(token);

    const url = `/viewerfileinfo/${encodeURIComponent(urn)}?projectId=${projectId}&fetchLatest=${fetchLatest}`;

    return forgeApi.get(url).then((response: AxiosResponse<IViewerProxyResponse>) => {
        return Promise.resolve(response.data);
    });
};

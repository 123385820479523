import { Box, createStyles, Table, TableBody, TableCell, TableContainer, TableRow, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(4, 2, 2, 2),
            height: "100%",
            [theme.breakpoints.up("lg")]: {
                padding: theme.spacing(6, 3, 3, 3),
            },
        },
        table: {
            minWidth: "100%",
            "& th": {
                width: "40%",
                fontWeight: "bold",
            },
        },
        tableRow: {
            verticalAlign: "top",
        },
        tableCell: {
            borderBottom: "none",
            padding: theme.spacing(0, 0.5, 0.5, 0),
            color: theme.palette.grey[600],
        },
        projectTitle: {
            fontSize: "1.3rem",
        },
        separator: {
            paddingBottom: theme.spacing(4),
        },
    }),
);

export interface IInfoDataType {
    key: string;
    value: string;
}

export const infoData: IInfoDataType[] = [
    {
        key: "Projekt",
        value: "Gümligen Baufeld A1",
    },
    {
        key: "Adresse",
        value: "Worbstrasse 201A, Muri bei Bern, BE 3073",
    },
    {
        key: "Arealfläche",
        value: "2451.5 m2",
    },
    {
        key: "Phase",
        value: "Vorproject",
    },
    {
        key: "Entwicklung",
        value: "HRS Real Estate AG, Michael Breitenmoser",
    },
    {
        key: "Projektleitung",
        value: "HRS Real Estate AG, Jaël Dölker",
    },
    {
        key: "Architektur",
        value: "Michael Meier und Marius Hug Architekten AG",
    },
    {
        key: "Bauingenieurweren",
        value: "Ingenta AG",
    },
    {
        key: "HLKKS",
        value: "Polke Ziege von Moos AG",
    },
    {
        key: "Elektro",
        value: "Amstein+Walthert AG",
    },
    {
        key: "Bauphysik",
        value: "Kopitsis Bauphysik AG",
    },
    {
        key: "Lanschaftsarchitektur",
        value: "USUS Landschaftsarchitektur AG",
    },
    {
        key: "BIM-Management",
        value: "Digital Insights GmbH",
    },
];

const Info: React.FC = () => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <TableContainer>
                <Table size="small" aria-label="info table" className={classes.table}>
                    <TableBody>
                        {infoData.map((info, index) => (
                            <TableRow className={classes.tableRow}>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    padding="none"
                                    className={clsx(classes.tableCell, {
                                        [classes.separator]: index === 3,
                                    })}
                                >
                                    {info.key}:
                                </TableCell>
                                <TableCell
                                    align="left"
                                    padding="none"
                                    className={clsx(classes.tableCell, {
                                        [classes.separator]: index === 3,
                                    })}
                                >
                                    <span className={index === 0 ? classes.projectTitle : ""}>{info.value}</span>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default Info;

import { defineMessages } from "react-intl";

export default defineMessages({
    messageButton: {
        id: "components__control-panel__message-button",
        defaultMessage: "Message",
    },
    pdfButton: {
        id: "components__control-panel__pdf-button",
        defaultMessage: "PDF File",
    },
});

import { Layout } from "@iolabs/layout";
import { useTheme } from "@material-ui/core";
import React, { ReactNode } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import DashboardDrawer from "./DashboardPage/DashboardDrawer";
import DashboardPage from "./DashboardPage/DashboardPage";
import ErrorPage from "./ErrorPage/ErrorPage";
import logo from "../assets/images/hrs-logo.png";
import IssuePage from "./IssuePage/IssuePage";

export type PageRoute = {
    path: string;
    component: ReactNode;
    menu?: any;
    showMenu?: boolean;
    exact?: boolean;
    routes?: PageRoute;
};

export const routes: PageRoute[] = [
    {
        path: "/issues/:modelId/:versionId",
        component: IssuePage,
        menu: DashboardDrawer,
        showMenu: true,
    },
    {
        path: "/",
        component: DashboardPage,
        menu: DashboardDrawer,
        showMenu: true,
    },
];

export const RouteWithSubRoutes = route => {
    return (
        <Route
            path={route.path}
            exact={route.exact}
            render={props => <route.component {...props} routes={route.routes} />}
        />
    );
};

const PageRouter: React.FC = () => {
    const theme = useTheme();

    const menu = (
        <Switch>
            {routes.map((route: PageRoute, index: number) => {
                if (route.showMenu) {
                    return (
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            render={props => <route.menu {...props} routes={route.routes} />}
                        />
                    );
                }
                return null;
            })}
        </Switch>
    );

    return (
        <Router>
            <LastLocationProvider>
                <Layout
                    logo={logo}
                    menu={menu}
                    stylesProps={{
                        headerBarBackground: theme.palette.primary.main,
                        drawerHeaderBackground: theme.palette.primary.main,
                        drawerPaperColor: theme.palette.grey[600],
                        drawerPaperBackground: theme.palette.border.main,
                        footerBarColor: theme.palette.white.main,
                        footerBarBackground: theme.palette.primary.light,
                    }}
                >
                    <Switch>
                        {routes.map((route: PageRoute, index: number) => (
                            <RouteWithSubRoutes key={index} {...route} />
                        ))}
                        <Route component={ErrorPage} />
                    </Switch>
                </Layout>
            </LastLocationProvider>
        </Router>
    );
};

export default PageRouter;

import { Box, createStyles, Theme, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { Model } from "../../redux/dashboard/reducer";
import CreateIssue from "./CreateIssue";
import ListIssue from "./ListIssue";
import { IIssueTab } from "./type";

export const useIssuesBoxStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
            "& > div": {
                height: "100%",
                "& > div": {
                    display: "flex",
                    flexFlow: "column nowrap",
                    height: "100%",
                },
            },
        },
        title: {
            position: "relative",
            padding: theme.spacing(2),
            paddingBottom: 0,
            "& h2": {
                textTransform: "uppercase",
                fontSize: "1rem",
            },
        },
        content: {
            flex: 1,
            overflowY: "auto",
            padding: theme.spacing(2),
        },
        contentList: {
            padding: `${theme.spacing(2)} 0`,
        },
        actions: {
            padding: theme.spacing(1),
        },
        actionsList: {
            justifyContent: "center",
        },
        titleBack: {
            marginLeft: theme.spacing(3),
        },
        backButton: {
            position: "absolute",
            top: "1rem",
            left: "-1rem",
        },
        box: {
            display: "flex",
            flexFlow: "column nowrap",
            flex: 1,
        },
        listSubheader: {
            backgroundColor: theme.palette.grey["100"],
            lineHeight: "30px",
        },
        divider: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        datepicker: {
            width: "100%",
            "& .MuiIconButton-root": {
                padding: "6px",
            },
            "& .MuiOutlinedInput-adornedStart": {
                paddingLeft: "7px",
            },
        },
        status: {
            paddingRight: "6px",
            borderLeftWidth: "3px",
            borderLeftStyle: "solid",
            height: "20px",
            maxHeight: "20px",
        },
        statusDraft: {
            borderLeftColor: theme.palette.status.light,
        },
        statusOpen: {
            borderLeftColor: theme.palette.secondary.main,
        },
        statusAnswered: {
            borderLeftColor: theme.palette.primary.main,
        },
        statusClosed: {
            borderLeftColor: theme.palette.status.main,
        },
        statusVoid: {
            borderLeftColor: theme.palette.status.main,
        },
        boxIssue: {
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
            borderBottom: `1px solid ${theme.palette.grey["500"]}`,
            "& h2": {
                fontSize: "1.1rem",
                margin: `${theme.spacing(0.4)} 0`,
            },
            "& p": {
                color: theme.palette.grey["500"],
                fontSize: ".9rem",
                padding: 0,
                margin: 0,
            },
        },
        boxStatus: {
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            flex: 1,
        },
        highlight: {
            color: theme.palette.status.dark,
        },
        field: {
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
            "& .MuiOutlinedInput-inputMarginDense": {
                paddingTop: "8px",
                paddingBottom: "8px",
            },
            "& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense": {
                paddingTop: "4px",
                paddingBottom: "4px",
            },
        },
        placeholder: {
            "& .MuiOutlinedInput-root": {
                color: theme.palette.grey[400],
            },
        },
        skeletonBox: {
            width: "100%",
            "& div, & span": {
                borderRadius: theme.spacing(0.3),
                margin: "1rem 0",
                "&::before": {
                    display: "none",
                },
            },
        },
        skeletonBoxIssue: {
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
            borderBottom: `1px solid ${theme.palette.grey["500"]}`,
            "& span": {
                borderRadius: theme.spacing(0.3),
                marginBottom: "0.4rem",
            },
        },
    }),
);

interface IIssuesBoxProps {
    // model: Model;
    versionId: number;
}

const IssuesBox: React.FC<IIssuesBoxProps> = (props: IIssuesBoxProps) => {
    const classes = useIssuesBoxStyles();
    const theme = useTheme();

    const [issueTab, setIssueTab] = useState<IIssueTab>(IIssueTab.List);

    const { versionId } = props;

    interface TabPanelProps {
        children?: React.ReactNode;
        dir?: string;
        index: number;
        value: number;
    }

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`issues-tabpanel-${index}`}
                key={`issues-tabpanel-${index}`}
                aria-labelledby={`issues-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box key={index} p={0}>
                        {children}
                    </Box>
                )}
            </Typography>
        );
    }

    const handleChangeTab = (tab: IIssueTab) => {
        setIssueTab(tab);
    };

    return (
        <Box className={classes.root}>
            <TabPanel key={IIssueTab.List} value={issueTab} index={IIssueTab.List} dir={theme.direction}>
                <ListIssue versionId={versionId} handleChangeTab={handleChangeTab} />
            </TabPanel>

            <TabPanel key={IIssueTab.Create} value={issueTab} index={IIssueTab.Create} dir={theme.direction}>
                <CreateIssue versionId={versionId} handleChangeTab={handleChangeTab} />
            </TabPanel>
        </Box>
    );
};

export default IssuesBox;

import { ApolloProvider } from "@apollo/react-hooks";
import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { setContext } from "apollo-link-context";
import { HttpLink } from "apollo-link-http";
import React from "react";
import { useKeycloak } from "react-keycloak";
import config from "../config/config";

let token;
const cache = new InMemoryCache();
const httpLink = new HttpLink({
    uri: config.api.baseUrl + config.graphql.baseUrl,
});

const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        },
    };
});

const client = new ApolloClient({
    cache,
    link: authLink.concat(httpLink),
});

interface IProps {
    children: any;
}

const GraphqlProvider: React.FC<IProps> = (props: IProps) => {
    const { children } = props;
    const { keycloak } = useKeycloak();

    token = keycloak.token;

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphqlProvider;

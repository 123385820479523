import { Badge, Button, createStyles, Theme, Tooltip, withStyles } from "@material-ui/core";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import messages from "./messages";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            color: theme.palette.border.dark,
            backgroundColor: "inherit !important",
            marginLeft: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingTop: theme.spacing(0.1),
            paddingBottom: theme.spacing(0.1),
            textTransform: "none",
            minWidth: "40px",
            height: "36px",
            "&:hover": {
                "& svg": {
                    fill: theme.palette.grey[600],
                },
            },
        },
        icon: {
            transform: "rotate(180deg) scaleX(-1)",
            fontSize: "1.5rem",
            fill: theme.palette.grey[600],
        },
    }),
);

const StyledBadge = withStyles((theme: Theme) =>
    createStyles({
        badge: {
            color: theme.palette.white.main,
            background: theme.palette.secondary.main,
            padding: "0 4px",
        },
    }),
)(Badge);

interface IIssueButtonProps {
    modelId: number;
    versionId: number;
    count?: number;
}

const IssueButton: React.FC<IIssueButtonProps> = (props: IIssueButtonProps) => {
    const classes = useStyles();
    const history = useHistory();

    const { modelId, versionId, count } = props;

    const handleRedirect = () => {
        history.push(`/issues/${modelId}/${versionId}`);
    };

    // translations
    const intl = useIntl();
    const transIssueButton = intl.formatMessage({ ...messages.issueButton });

    return (
        <Tooltip title={transIssueButton}>
            <Button variant="text" size="small" className={classes.button} onClick={() => handleRedirect()}>
                {/*<Icon name="file-pdf" size={20} />*/}

                <StyledBadge badgeContent={count} max={100}>
                    <InsertDriveFileOutlinedIcon className={classes.icon} />
                </StyledBadge>
            </Button>
        </Tooltip>
    );
};

export default IssueButton;

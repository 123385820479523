import { useSelector } from "react-redux";
import {
    selectFilters,
    selectIsolate,
    selectIssuables,
    selectIssues,
    selectModelById,
    selectModels,
    selectModelVariantIds,
    selectOverlap,
    selectPanel,
    selectRepresent,
    selectSpeed,
    selectSurrounding,
    selectSyncView,
    selectTurntable,
} from "./selector";

export const useModelsById = (modelId: number) => useSelector(selectModelById(modelId));
export const useModels = () => useSelector(selectModels);
export const useModelVariantIds = () => useSelector(selectModelVariantIds);

export const usePanel = () => useSelector(selectPanel);
export const usePanelRepresent = () => useSelector(selectRepresent);
export const usePanelSurrounding = () => useSelector(selectSurrounding);
export const usePanelIsolate = () => useSelector(selectIsolate);
export const usePanelSyncView = () => useSelector(selectSyncView);
export const usePanelOverlap = () => useSelector(selectOverlap);
export const usePanelTurntable = () => useSelector(selectTurntable);
export const usePanelSpeed = () => useSelector(selectSpeed);

export const useFilters = () => useSelector(selectFilters);

export const useIssuables = () => useSelector(selectIssuables);
export const useIssues = () => useSelector(selectIssues);

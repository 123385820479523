import { Icon } from "@iolabs/layout";
import { useGlobalDialog } from "@iolabs/wip-dialog";
import { Button, createStyles, Theme, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            color: theme.palette.border.dark,
            backgroundColor: "inherit !important",
            marginLeft: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingTop: theme.spacing(0.1),
            paddingBottom: theme.spacing(0.1),
            textTransform: "none",
            minWidth: "40px",
            height: "36px",
            "&:hover": {
                "& svg": {
                    fill: theme.palette.grey[600],
                },
            },
        },
    }),
);

const MessageButton: React.FC = () => {
    const classes = useStyles();
    const { setGlobalDialogOpen } = useGlobalDialog();

    // translations
    const intl = useIntl();
    const transMessageButton = intl.formatMessage({
        ...messages.messageButton,
    });

    return (
        <Tooltip title={transMessageButton}>
            <Button variant="text" size="small" className={classes.button} onClick={() => setGlobalDialogOpen(true)}>
                <Icon name="message" size={20} />
            </Button>
        </Tooltip>
    );
};

export default MessageButton;

import { Button, createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            color: theme.palette.grey[600],
            backgroundColor: "inherit !important",
            marginLeft: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingTop: theme.spacing(0.1),
            paddingBottom: theme.spacing(0.1),
            textTransform: "none",
            minWidth: "40px",
            height: "36px",
            "&:hover": {
                "& svg": {
                    fill: theme.palette.grey[600],
                },
            },
        },
    }),
);

interface IRedirectButton {
    title: string;
    path: string;
}

const RedirectButton: React.FC<IRedirectButton> = (props: IRedirectButton) => {
    const classes = useStyles();
    const history = useHistory();

    const { title, path } = props;

    const handleRedirect = () => {
        history.push(path);
    };

    return (
        <Button variant="text" size="small" className={classes.button} onClick={handleRedirect}>
            {title}
        </Button>
    );
};

export default RedirectButton;

import { onPageTitle } from "@iolabs/app";
import { DispatchAction } from "@iolabs/redux-utils";
import { createStyles, Grid, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Route, Switch, useParams } from "react-router-dom";
import IssuesBox from "../../components/Issues/IssuesBox";
import Viewer from "../../components/Viewer/Viewer";
import { Project, ProjectFileVersion, useGetProjectQuery } from "../../graphql/generated/graphql";
import IssuePanel from "./IssuePanel";
import messages from "./messages";

const controlPanelHeight = 45;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        controlPanel: {
            height: `${controlPanelHeight}px`,
            minHeight: `${controlPanelHeight}px`,
            background: theme.palette.border[500],
        },
        issuesContainer: {
            height: "100%",
            alignContent: "flex-start",
        },
        issuesItem: {
            height: `calc(100% - ${controlPanelHeight}px)`,
            [theme.breakpoints.up("lg")]: {},
        },
    }),
);

const IssuePage: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();
    const { modelId, versionId } = useParams();

    const { data, loading, error } = useGetProjectQuery({
        variables: {
            code: "01", // todo how to get project code?
        },
    });

    // translations
    const intl = useIntl();
    const transPageTitle = intl.formatMessage({ ...messages.pageTitle });

    useEffect(() => {
        dispatch(onPageTitle({ pageTitle: transPageTitle }));
    }, [transPageTitle]);

    const project: Project = data?.projects?.find(pr => pr?.code == "01") as Project;

    const projectFileVersion: ProjectFileVersion = project?.projectFiles
        ?.find(pf => pf?.id == modelId)
        ?.projectFileVersions?.find(pfv => pfv?.id == versionId) as ProjectFileVersion;

    const fileVersionUrn: string = projectFileVersion?.fileVersionsInExternalSystem?.find(
        fves => fves?.externalSystem?.code == "forge",
    )?.externalId as string;

    return (
        <Switch>
            <Route
                render={() => (
                    <Grid container spacing={0} className={classes.issuesContainer}>
                        <Grid item xs={12} sm={12} className={classes.controlPanel}>
                            <IssuePanel />
                        </Grid>

                        <Grid item xs={12} sm={12} md={9} className={classes.issuesItem}>
                            {!loading && (
                                <Viewer
                                    projectId={project.id as number}
                                    projectFileVersionId={projectFileVersion.id as number}
                                    urn={fileVersionUrn}
                                    disableFetchLatest={true}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} className={classes.issuesItem}>
                            <IssuesBox versionId={versionId} />
                        </Grid>
                    </Grid>
                )}
            />
        </Switch>
    );
};

export default IssuePage;

import DateFnsUtils from "@date-io/date-fns";
import { onError } from "@iolabs/app";
import { DispatchAction } from "@iolabs/redux-utils";
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    ListSubheader,
    MenuItem,
    TextField,
    useTheme,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import TodayIcon from "@material-ui/icons/Today";
import { Skeleton } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import clsx from "clsx";
import "date-fns";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import {
    CreateIssueMutationVariables,
    useCreateIssueMutation,
    useGetFormConfigQuery,
} from "../../graphql/generated/graphql";
import { onCreateIssue, useIssuables } from "../../redux/dashboard";
import globalMessages from "../App/messages";
import { useIssuesBoxStyles } from "./IssuesBox";
import { IIssueState, IIssueTab } from "./type";

interface ICreateIssueProps {
    handleChangeTab: (tab: IIssueTab) => void;
    versionId: number;
}

const CreateIssue: React.FC<ICreateIssueProps> = (props: ICreateIssueProps) => {
    const classes = useIssuesBoxStyles();
    const theme = useTheme();
    const dispatch = useDispatch<DispatchAction>();
    const { handleChangeTab } = props;

    const issuables = useIssuables();

    const [createIssueMutation] = useCreateIssueMutation({
        refetchQueries: ["GetIssuesForProjectLazyQuery"],
    });
    const { data: formConfigData, loading: formConfigLoading, error: formConfigError } = useGetFormConfigQuery({
        variables: {
            projectCode: "01",
        },
    });

    const intl = useIntl();
    const transLoadingDataError = intl.formatMessage({ ...globalMessages.loadingDataError });

    if (formConfigError) {
        dispatch(
            onError({
                errorMessage: transLoadingDataError,
            }),
        );
    }

    // Number 0 representing placeholder for select fields
    const [issue, setIssue] = useState<IIssueState>({
        type: 0,
        status: 0,
        title: "",
        assignTo: 0,
        description: "",
        dueDate: null,
        location: "",
        locationDetails: "",
        owner: 0,
        rootCause: 0,
    });
    const [selectTypes, setSelectTypes] = useState<any[]>([]);
    const [selectStatuses, setSelectStatuses] = useState<any[]>([]);
    const [selectCauses, setSelectCauses] = useState<any[]>([]);
    const [issueData, setIssueData] = useState<Partial<CreateIssueMutationVariables>>({});

    const handleChange = (prop: keyof IIssueState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setIssue({ ...issue, [prop]: event.target.value });
    };

    const handleChangeDate = (date: Date | null) => {
        setIssue({ ...issue, ["dueDate"]: date });
    };

    const handleCreate = async () => {
        const dataToSend: CreateIssueMutationVariables = {
            ...issueData,
            ...{
                typeId: issue.type,
                statusId: issue.status,
                name: issue.title,
                dueDate: issue.dueDate,
                causeId: issue.rootCause,
                locationDescription: issue.locationDetails,
                description: issue.description,
            },
        } as CreateIssueMutationVariables;

        await createIssueMutation({
            variables: dataToSend,
        })
            .then(createIssueData => {
                console.log("createIssueData");
                console.log(createIssueData);
            })
            .catch(createIssueError => {
                console.log("createIssueError");
                console.log(createIssueError);
            });

        dispatch(onCreateIssue({ issue }));
        handleChangeTab(IIssueTab.List);
    };

    const isValid = (): boolean => {
        return issue.type !== null && issue.status !== null && issue.title !== "";
    };

    const renderStatus = (status: any) => {
        return (
            <span
                className={classes.status}
                style={{
                    borderLeftColor: status?.highlighColor,
                }}
            />
        );

        // switch (type) {
        //     case IIssueStatusType.Draft:
        //         return <span className={clsx(classes.status, classes.statusDraft)} />;
        //
        //     case IIssueStatusType.Open:
        //         return <span className={clsx(classes.status, classes.statusOpen)} />;
        //
        //     case IIssueStatusType.Answered:
        //         return <span className={clsx(classes.status, classes.statusAnswered)} />;
        //
        //     case IIssueStatusType.Closed:
        //         return <span className={clsx(classes.status, classes.statusClosed)} />;
        //
        //     case IIssueStatusType.Void:
        //         return <span className={clsx(classes.status, classes.statusVoid)} />;
        //
        //     default:
        //         return null;
        // }
    };

    const issueLocationCollected = (issueData: Partial<CreateIssueMutationVariables>) => {
        setIssueData(issueData);
    };

    useEffect(() => {
        let selectTypesItems: any[] = [];
        formConfigData?.types?.map((type, index) => {
            if (type?.children!.length > 0) {
                selectTypesItems.push(
                    <ListSubheader key={index} className={classes.listSubheader}>
                        {type?.name}
                    </ListSubheader>,
                );
                type?.children?.map((item, index2) => {
                    selectTypesItems.push(
                        <MenuItem key={`${index}-${index2}`} value={item?.id as number}>
                            {item?.name}
                        </MenuItem>,
                    );
                });
            }
        });
        setSelectTypes(selectTypesItems);
    }, [formConfigData, setSelectTypes]);

    useEffect(() => {
        let selectStatusesItems: any[] = [];
        formConfigData?.statuses?.map((status, index) => {
            selectStatusesItems.push(
                <MenuItem key={index} value={status?.id as number}>
                    {renderStatus(status)} {status?.name}
                </MenuItem>,
            );
        });
        setSelectStatuses(selectStatusesItems);
    }, [formConfigData, setSelectStatuses, renderStatus]);

    useEffect(() => {
        let selectCausesItems: any[] = [];
        formConfigData?.causes?.map((cause, index) => {
            selectCausesItems.push(
                <MenuItem key={index} value={cause?.id as number}>
                    {cause?.name}
                </MenuItem>,
            );
        });
        setSelectCauses(selectCausesItems);
    }, [formConfigData, setSelectCauses]);

    useEffect(() => {
        issuables[0]?.collectIssueData(issueLocationCollected);
    }, [issuables]);

    useEffect(() => {
        return () => {
            if (issuables[0]?.stopCollecting) {
                issuables[0]?.stopCollecting();
            }
        };
    }, []);

    return (
        <>
            <IconButton
                aria-label="back"
                size="small"
                className={classes.backButton}
                onClick={() => handleChangeTab(IIssueTab.List)}
            >
                <ChevronLeftIcon fill={theme.palette.grey["600"]} />
            </IconButton>
            <DialogTitle id="issues-dialog-title" className={clsx(classes.title, classes.titleBack)}>
                Neue Aufgabe
                {/* New Issue */}
            </DialogTitle>
            <DialogContent className={classes.content}>
                {formConfigLoading || formConfigError ? (
                    <Box className={classes.skeletonBox}>
                        <Skeleton variant="rect" height={35} />
                        <Skeleton variant="rect" height={35} />
                        <Skeleton variant="rect" height={35} />
                        <Divider className={classes.divider} />
                        <Skeleton variant="rect" height={35} />
                        <Skeleton variant="rect" height={35} />
                        <Skeleton variant="rect" height={35} />
                        <Divider className={classes.divider} />
                        <Skeleton variant="rect" height={119} />
                    </Box>
                ) : (
                    <Box className={classes.box}>
                        <Box className={classes.field}>
                            <TextField
                                id="select-type"
                                required
                                fullWidth
                                select
                                label="Typ" // Type
                                variant="outlined"
                                margin="dense"
                                value={issue.type}
                                onChange={handleChange("type")}
                                className={issue.type === 0 ? classes.placeholder : ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                                <MenuItem key={0} value="0" disabled>
                                    Auswählen...
                                    {/* Select... */}
                                </MenuItem>
                                {selectTypes}
                            </TextField>
                        </Box>

                        <Box className={classes.field}>
                            <TextField
                                id="select-status"
                                required
                                fullWidth
                                select
                                label="Status" // Status
                                variant="outlined"
                                margin="dense"
                                value={issue.status}
                                onChange={handleChange("status")}
                                className={issue.status === 0 ? classes.placeholder : ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                                <MenuItem key={0} value="0" disabled>
                                    Auswählen...
                                    {/* Select... */}
                                </MenuItem>
                                {selectStatuses}
                            </TextField>
                        </Box>
                        <Box className={classes.field}>
                            <TextField
                                id="title-field"
                                required
                                fullWidth
                                label="Titel" // Title
                                placeholder="Angeben..." // Specify...
                                type="text"
                                variant="outlined"
                                margin="dense"
                                value={issue.title}
                                onChange={handleChange("title")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        <Divider className={classes.divider} />

                        {/*<Box className={classes.field}>
                        <TextField
                            id="select-assign-to"
                            select
                            fullWidth
                            label="Zuweisen zu" // Assign To
                            variant="outlined"
                            margin="dense"
                            value={issue.assignTo}
                            onChange={handleChange("assignTo")}
                            className={issue.assignTo === 0 ? classes.placeholder : ""}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                            <MenuItem key={0} value="0" disabled>
                                Benutzer, Rolle o. Firma wählen...
                                 Select User, Role or Company...
                            </MenuItem>
                            {issueAssignTo.map((item: IIssueSelect, index: number) => {
                                return item.isCategory ? (
                                    <ListSubheader key={index} className={classes.listSubheader}>
                                        {item.name}
                                    </ListSubheader>
                                ) : (
                                    <MenuItem key={index} value={item.id}>
                                        {item.name}
                                        {item.description ? ` (${item.description})` : ""}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    </Box>*/}
                        <Box className={classes.field}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    id="datapicker-field"
                                    label="Fälling am" //  Due Date
                                    placeholder="Datum wählen..." // Choose date...
                                    variant="inline"
                                    inputVariant="outlined"
                                    margin="dense"
                                    disableToolbar
                                    format="MM/dd/yyyy"
                                    value={issue.dueDate}
                                    onChange={handleChangeDate}
                                    InputAdornmentProps={{ position: "start" }}
                                    keyboardIcon={<TodayIcon fontSize="small" />}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                    className={classes.datepicker}
                                />
                            </MuiPickersUtilsProvider>
                        </Box>

                        {/*<Box className={classes.field}>
                        <TextField
                            id="location-field"
                            label="Standort" // Location
                            placeholder="Standort eingeben..." // Enter location...
                            type="text"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            value={issue.location}
                            onChange={handleChange("location")}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>*/}
                        <Box className={classes.field}>
                            <TextField
                                id="location-details-field"
                                label="Standortdetails" // Location Details
                                placeholder="Standort eingeben..." // Enter location...
                                type="text"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                value={issue.locationDetails}
                                onChange={handleChange("locationDetails")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        {/*<Box className={classes.field}>
                        <TextField
                            id="select-owner"
                            select
                            label="Ersteller" // Owner
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            value={issue.owner}
                            onChange={handleChange("owner")}
                            className={issue.owner === 0 ? classes.placeholder : ""}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                            <MenuItem key={0} value="0" disabled>
                                Auswählen...
                                 Select...
                            </MenuItem>
                            {issueOwners.map((item: IIssueSelect, index: number) => {
                                return (
                                    <MenuItem key={index} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    </Box>*/}

                        <Box className={classes.field}>
                            <TextField
                                id="select-root-cause"
                                select
                                label="Fehlerursache" // Root Cause
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                value={issue.rootCause}
                                onChange={handleChange("rootCause")}
                                className={issue.rootCause === 0 ? classes.placeholder : ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                                <MenuItem key={0} value="0" disabled>
                                    Auswählen...
                                    {/* Select... */}
                                </MenuItem>
                                {selectCauses}
                            </TextField>
                        </Box>

                        <Divider className={classes.divider} />

                        <Box className={classes.field}>
                            <TextField
                                id="description-field"
                                label="Beschreibung" // Description
                                placeholder="Angeben..." // Specify...
                                multiline
                                rows={5}
                                type="text"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                value={issue.description}
                                onChange={handleChange("description")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </Box>
                )}
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button variant="contained" color="default" onClick={() => handleChangeTab(IIssueTab.List)}>
                    Abbrechen {/* Cancel */}
                </Button>
                <Button variant="contained" color="primary" disabled={!isValid()} onClick={handleCreate}>
                    Erstellen {/* Create */}
                </Button>
            </DialogActions>
        </>
    );
};

export default CreateIssue;

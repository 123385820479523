import deepmerge from "deepmerge";
import { Vector3, Matrix4 } from "three";

export const ExtensionID: string = "Viewing.Extension.TurnTable";

export interface ITurnTableExtensionOptions {
    showUi?: boolean;
    speed?: number;
}

const DefaultOptions: ITurnTableExtensionOptions = {
    showUi: false,
    speed: 50,
};

export const register = () => {
    class TurnTableExtension extends Autodesk.Viewing.Extension {
        private _group?: Autodesk.Viewing.UI.ControlGroup;
        private _button?: Autodesk.Viewing.UI.Button;
        private _started?: boolean;
        private _speed: number;

        constructor(viewer: Autodesk.Viewing.GuiViewer3D, options?: ITurnTableExtensionOptions) {
            // @ts-ignore
            const opts = deepmerge(DefaultOptions, options);
            super(viewer, opts);
            this._speed = opts.speed;
            this.viewer = viewer;
            this._started = false;
            this.customize = this.customize.bind(this);
        }

        load() {
            if (this.viewer.model.getInstanceTree()) {
                this.customize();
            } else {
                this.viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, this.customize);
            }
            return true;
        }
        unload() {
            console.log("TurnTableExtension is now unloaded!");
            // Clean our UI elements if we added any
            if (this._group) {
                if (this._button) this._group.removeControl(this._button);
                if (this._group.getNumberOfControls() === 0) {
                    this.viewer.toolbar.removeControl(this._group);
                }
            }
            return true;
        }

        rotateCamera() {
            if (this._started) {
                requestAnimationFrame(() => {
                    this.rotateCamera();
                });
            }

            const nav = this.viewer.navigation;
            const up = nav.getCameraUpVector();
            const axis = new Vector3(0, 0, 1);
            let speed = (10.0 * Math.PI) / 180;

            // speed = speed * ((Math.pow(this._speed,2)) / 2500);

            speed = speed * (this._speed / 50);

            if (speed !== 0) {
                const matrix = new Matrix4().makeRotationAxis(axis, speed * 0.1);

                let pos = nav.getPosition();
                pos.applyMatrix4(matrix);
                up.applyMatrix4(matrix);
                nav.setView(pos, new Vector3(0, 0, 0));
                nav.setCameraUpVector(up);
                // const viewState = this.viewer.getState();
                // viewer.restoreState(viewState);
            }
        }

        toggle(targetState) {
            this._started = targetState;
            if (this._started) this.rotateCamera();
        }

        setSpeed(speed) {
            this._speed = speed;
        }

        customize() {
            if (this.options.showUi) {
                this._button = new Autodesk.Viewing.UI.Button("turnTableButton");
                this._button.addClass("toolbarCameraRotation");
                this._button.setToolTip("Start/Stop Camera rotation");

                // _group
                this._group = new Autodesk.Viewing.UI.ControlGroup("CameraRotateToolbar");
                this._group.addControl(this._button);
                this.viewer.toolbar.addControl(this._group);

                this._button.onClick = () => {
                    this.toggle(!this._started);
                };
            }
        }
    }
    // register extension - we need to do it here so extension is loaded by viewer
    Autodesk.Viewing.theExtensionManager.registerExtension(ExtensionID, TurnTableExtension);
};

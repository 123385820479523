import { drawerWidth, Icon, onDrawer } from "@iolabs/layout";
import { DispatchAction } from "@iolabs/redux-utils";
import { isMenuItemActive } from "@iolabs/utils";
import { useGlobalDialog } from "@iolabs/wip-dialog";
import {
    createStyles,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Theme,
    useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.grey[700],
            width: `${drawerWidth}px`,
        },
        skeletonBox: {},
        skeleton: {
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
            width: `${drawerWidth}px`,
            "& div": {
                background: theme.palette.primary.dark,
            },
        },
        skeletonCircle: {
            marginRight: theme.spacing(1),
        },
        skeletonItem: {
            marginRight: theme.spacing(0.5),
            flexGrow: 1,
        },
        listItemIcon: {
            minWidth: "38px",
        },
        menuIcon: {},
        menuText: {
            fontSize: "1rem",
            paddingLeft: theme.spacing(1),
            whiteSpace: "nowrap",
            "& span": {
                lineHeight: "1.2rem",
            },
        },
        listItem: {
            color: theme.palette.grey[700],
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1),
        },
        collapseButton: {
            color: theme.palette.grey[700],
        },
        nested: {
            paddingLeft: theme.spacing(5),
        },
        active: {
            color: theme.palette.white.main,
        },
        divider: {
            backgroundColor: theme.palette.grey[700],
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
        },
    }),
);

interface IDashboardDrawerProps extends React.HTMLAttributes<HTMLElement> {}

const DashboardDrawer: React.FC<IDashboardDrawerProps> = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { setGlobalDialogOpen } = useGlobalDialog();
    const { code } = useParams();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch<DispatchAction>();
    const [level, setLevel] = useState<number>(0);
    const [content, setContent] = useState<ReactNode | null>(null);

    const handleRouter = (route: string = "", level: number = 0) => {
        if (route !== "") {
            history.push(route);
        }
        setLevel(level);
        dispatch(onDrawer({ drawer: level === 0 }));
    };

    useEffect(() => {
        switch (level) {
            default: {
                setContent(
                    <>
                        <ListItem
                            button
                            onClick={() => handleRouter(`/`)}
                            className={clsx(classes.listItem, {
                                [classes.active]: isMenuItemActive(location.pathname, "/"),
                            })}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <Icon
                                    name="planner-module"
                                    fill={
                                        isMenuItemActive(location.pathname, "/")
                                            ? theme.palette.white.main
                                            : theme.palette.grey[700]
                                    }
                                    size={25}
                                    className={classes.menuIcon}
                                />
                            </ListItemIcon>
                            <ListItemText primary="Versionsvergleich" className={classes.menuText} />
                        </ListItem>
                        <ListItem
                            button
                            onClick={() => setGlobalDialogOpen(true)}
                            className={clsx(classes.listItem, {
                                [classes.active]: false,
                            })}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <Icon
                                    name="chart-line"
                                    fill={theme.palette.grey[700]}
                                    size={25}
                                    className={classes.menuIcon}
                                />
                            </ListItemIcon>
                            <ListItemText primary="Excel Tool" className={classes.menuText} />
                        </ListItem>
                        <ListItem
                            button
                            onClick={() => setGlobalDialogOpen(true)}
                            className={clsx(classes.listItem, {
                                [classes.active]: false,
                            })}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <Icon
                                    name="new-work-stream"
                                    fill={theme.palette.grey[700]}
                                    size={25}
                                    className={classes.menuIcon}
                                />
                            </ListItemIcon>
                            <ListItemText primary="Neues Modul" className={classes.menuText} />
                        </ListItem>
                    </>,
                );
                break;
            }
        }
    }, [level, setContent, location, classes, setGlobalDialogOpen, theme, code]);

    return (
        <List component="nav" aria-labelledby="menu" className={classes.root}>
            {content}
        </List>
    );
};

export default DashboardDrawer;

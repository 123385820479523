import React, { useEffect } from "react";
import { onRegisterIssuable, onUnregisterIssuable } from "../../redux/dashboard";
import { slugify } from "../../utils/Slugify";
import { useDispatch } from "react-redux";
import { IIssuable } from "../../redux/dashboard/reducer";

const IssuableComponent: React.FC<IIssuable> = (props: IIssuable) => {
    const { id, name, disabled, collectIssueData, renderIssues, stopCollecting } = props;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            onRegisterIssuable({
                issuable: {
                    id: slugify(id),
                    name: name,
                    collectIssueData: collectIssueData,
                    renderIssues: renderIssues,
                    disabled: disabled,
                    stopCollecting: stopCollecting,
                },
            }),
        );
        return () => {
            // componentWillUnmount
            dispatch(onUnregisterIssuable({ id: slugify(id) }));
        };
    }, [id]);

    return <></>;
};

export default IssuableComponent;

import { withStyles } from "@material-ui/core";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

export const GlobalCss = withStyles({
    "@global": {
        // forge viewer overrides
        ".adsk-viewing-viewer.dark-theme .adsk-button.active, .adsk-viewing-viewer.dark-theme .adsk-button:focus": {
            color: "#3b8fcd !important",
        },
        ".adsk-viewing-viewer.dark-theme .adsk-button:hover": {
            color: "#3b8fcd !important",
            border: "1px solid #3b8fcd !important",
        },
        // hide viewer toolbar on mobile devices
        "@media screen and (max-width: 959px)": {
            ".adsk-viewing-viewer .adsk-toolbar": {
                display: "none !important",
            },
        },
        // change toolbar size
        ".adsk-viewing-viewer .adsk-toolbar": {
            transform: "scale(0.8) !important",
        },
        sup: {
            fontSize: "70%",
        },
    },
})(() => null);

const iolabsTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#ff5f00",
            light: "#ff8000",
            100: "#ffdbb8",
            300: "#ffbf87",
        },
        secondary: {
            main: "#3272ff",
        },
        error: {
            main: "#ff0000",
        },
        white: {
            main: "#fff",
        },
        black: {
            main: "#000",
            dark: "#424242", // text
        },
        border: {
            main: "#cccccc", // header and footer background
            dark: "#c3c3c3", // main menu icon
            light: "#d9d9d9", // main menu background
            100: "#f9f9f9",
            200: "#e7e7e7",
            300: "#e0e0e0",
            400: "#d7d1d1",
            500: "#dedede",
        },
        viewer: {
            main: "rgba(34, 34, 34, .94)",
        },
        status: {
            main: "#dedede", // success
            light: "#f5a707", // warning
            dark: "#fc0008", // error
        },
        background: {
            default: "#fff",
        },
        action: {
            disabledBackground: "#a6a5a8", // override disabled background
        },
        green: {
            main: "#21c444",
        },
        orange: {
            main: "#f8b80b",
        },
        red: {
            main: "#fc0008",
        },
        bunt: {
            50: "#060813", // 5%
            100: "#0c1027",
            200: "#13183a",
            300: "#19204d",
            400: "#1f2860",
            500: "#253074",
            600: "#28337a",
            700: "#32409a",
            800: "#2848ad",
            900: "#5361c7",
            A100: "#7884d3",
            A200: "#8b96da",
            A400: "#9fa7e0",
            A700: "#ecedf9", // 100%
        },
        violet: {
            50: "#120811", // 5%
            100: "#240f22",
            200: "#361733",
            300: "#481e44",
            400: "#5a2655",
            500: "#6c2d66",
            600: "#7e3577",
            700: "#903c87",
            800: "#a34499",
            900: "#b44ba9",
            A100: "#bb5db2",
            A200: "#c36fba",
            A400: "#ca81c3",
            A700: "#d293cc",
        },
        waterloo: {
            main: "#7e829d",
        },
    },
    typography: {
        h5: {
            fontSize: "1.2rem",
            lineHeight: 1,
        },
    },
    spacing: factor => `${0.6 * factor}rem`, // bootstrap strategy
    overrides: {
        MuiTableCell: {
            root: {
                borderBottom: "1px solid #000",
            },
            sizeSmall: {
                padding: "6px 6px 6px 6px",
            },
        },
        MuiOutlinedInput: {
            root: {
                "& $notchedOutline": {
                    borderColor: "#757575",
                },
                "&$focused $notchedOutline": {
                    borderColor: "#757575",
                },
                color: "#757575",
            },
            notchedOutline: {},
        },
        MuiInputLabel: {
            root: {
                color: "#757575",
            },
        },
    },
});

export default iolabsTheme;

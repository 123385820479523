import deepmerge from "deepmerge";
import EventEmitter from "events";

export const ExtensionID: string = "Viewing.Extension.Surrounding";
const SURROUNDING_ID_LOADED: string = "SURROUNDING_ID_LOADED";

export interface ISurroundingExtensionOptions {
    nameRegexp: RegExp;
}

const DefaultOptions: ISurroundingExtensionOptions = {
    nameRegexp: /_surrounding_/,
};

export const register = () => {
    class SurroundingExtension extends Autodesk.Viewing.Extension {
        private _surroundingEnabled?: boolean;
        private _surroundingDbId?: number;
        private _eventsEmitter?: EventEmitter.EventEmitter;

        constructor(viewer: Autodesk.Viewing.GuiViewer3D, options?: ISurroundingExtensionOptions) {
            // @ts-ignore
            const opts = deepmerge(DefaultOptions, options);
            super(viewer, opts);
            this.viewer = viewer;
            this._eventsEmitter = new EventEmitter.EventEmitter();
        }

        load() {
            // itentionally does not await
            this.prepareObjects();
            return true;
        }

        async prepareObjects() {
            const instanceTree = await this.getInstanceTree();
            const children: number[] = [];
            instanceTree.enumNodeChildren(
                instanceTree.getRootId(),
                childId => {
                    children.push(childId);
                },
                false,
            );

            let counter = children.length;

            children.forEach(childId => {
                this.viewer.model.getBulkProperties([childId], ["Name"], props => {
                    const name = props[0].properties[0].displayValue;
                    if (name.match(this.options.nameRegexp)) {
                        this._surroundingDbId = childId;
                    }

                    if (--counter === 0) {
                        this._eventsEmitter?.emit(SURROUNDING_ID_LOADED);
                    }
                });
            });
        }

        async getInstanceTree() {
            return new Promise<Autodesk.Viewing.InstanceTree>(async resolve => {
                let instanceTree = this.viewer?.model?.getInstanceTree();
                if (instanceTree) {
                    resolve(instanceTree);
                } else {
                    const instanceTreeCreatedListener = async () => {
                        instanceTree = this.viewer.model.getInstanceTree();
                        resolve(instanceTree);
                        this.viewer.removeEventListener(
                            Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
                            instanceTreeCreatedListener,
                        );
                    };
                    this.viewer.addEventListener(
                        Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
                        instanceTreeCreatedListener,
                    );
                }
            });
        }

        async getSurroundingObjectId() {
            return new Promise<number>(async resolve => {
                if (this._surroundingDbId != undefined) {
                    resolve(this._surroundingDbId);
                } else {
                    const surroundingIdLoadedListener = async () => {
                        this._eventsEmitter?.off(SURROUNDING_ID_LOADED, surroundingIdLoadedListener);
                        resolve(this._surroundingDbId);
                    };
                    this._eventsEmitter?.on(SURROUNDING_ID_LOADED, surroundingIdLoadedListener);
                }
            });
        }

        async setSurrounding(enabled: boolean) {
            const id = await this.getSurroundingObjectId();
            if (id) {
                this.viewer.impl.visibilityManager.setNodeOff(id, !enabled);
                // if (enabled) {
                //     this.viewer.show(id);
                //     this.viewer.setGhosting(false);
                // }
                // else {
                //     this.viewer.hide(id);
                //     this.viewer.setGhosting(true);
                // };
            }
        }

        unload() {
            return true;
        }
    }
    // register extension - we need to do it here so extension is loaded by viewer
    Autodesk.Viewing.theExtensionManager.registerExtension(ExtensionID, SurroundingExtension);
};

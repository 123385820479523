import {
    Box,
    createStyles,
    FormControl,
    FormControlLabel,
    FormGroup,
    MenuItem,
    Select,
    Theme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import {
    Maybe,
    ProjectFile,
    ProjectFileVersion,
    ProjectFileVersionViewable,
    useGetIssuesIdsForProjectFileVersionLazyQuery,
    ViewableType,
} from "../../graphql/generated/graphql";
import IssueButton from "./IssueButton";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "40px",
            padding: theme.spacing(0.5),
            zIndex: 10,
            pointerEvents: "none",
        },
        formGroup: {
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "space-between",
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            "& label": {
                marginLeft: theme.spacing(0.5),
                marginRight: theme.spacing(1),
                "& span": {
                    fontSize: ".9rem",
                },
            },
            "&>*": {
                pointerEvents: "all",
            },
        },
        formControlLabel: {
            marginBottom: theme.spacing(0.5),
        },
        formControl: {
            "& .MuiOutlinedInput-inputMarginDense": {
                padding: "4px 22px 4px 8px",
            },
            "& .MuiSelect-iconOutlined": {
                right: 0,
            },
        },
        select: {
            minWidth: "6.5rem",
            marginLeft: theme.spacing(1),
            fontSize: ".9rem",
        },
    }),
);

interface IVersionSelector extends React.HTMLAttributes<HTMLElement> {
    modelId: number;
    count: number;
    models: ProjectFile[];
    onVersionSelected: (projectFileVersion: ProjectFileVersion) => void;
    onViewableSelected: (projectFileVersionViewable: ProjectFileVersionViewable) => void;
    hideViewableSelector?: boolean;
}

const VersionSelector: React.FC<IVersionSelector> = (props: IVersionSelector) => {
    const classes = useStyles();

    const [selectedModel, setSelectedModel] = useState<number>(-1);
    const [version, setVersion] = useState<number>(-1);
    const [variant, setVariant] = useState<number>(-1);

    const { modelId, models, onVersionSelected, onViewableSelected, hideViewableSelector } = props;

    const [
        getIssuesForProjectQuery,
        { data: projectIssuesData, loading: projectIssuesLoading, error: projectIssuesError },
    ] = useGetIssuesIdsForProjectFileVersionLazyQuery({
        fetchPolicy: "network-only", // disable graphql query caching
    });

    useEffect(() => {
        getIssuesForProjectQuery({
            variables: {
                projectFileVersionId: version.toString(),
            },
        });
    }, [version]);

    const handleChangeIfc = (event: React.ChangeEvent<{ value: unknown }>) => {
        // const versionId: number = models?.find(pf => pf.id === event.target.value)?.projectFileVersions?[0].id;
        setVersion(-1);
        setVariant(-1);
        setSelectedModel(event.target.value as number);
    };

    const setVersionById = (versionId: number) => {
        setVersion(versionId);
        const projectFileVersion = models
            ?.find(pf => pf.id === selectedModel)
            ?.projectFileVersions?.find(pfv => pfv?.id === versionId);
        onVersionSelected(projectFileVersion as ProjectFileVersion);
    };

    const setVariantById = (variantId: number) => {
        setVariant(variantId);
        const projectFileVersionViewable = models
            ?.find(pf => pf.id === selectedModel)
            ?.projectFileVersions?.find(pfv => pfv?.id === version)
            ?.viewables?.find(v => v?.id === variantId);
        onViewableSelected(projectFileVersionViewable as ProjectFileVersionViewable);
    };

    const handleChangeVersion = (event: React.ChangeEvent<{ value: unknown }>) => {
        const versionId = event.target.value as number;
        setVersionById(versionId);
    };

    const handleChangeVariant = (event: React.ChangeEvent<{ value: unknown }>) => {
        const variantId = event.target.value as number;
        setVariantById(variantId);
    };

    const count = projectIssuesLoading ? undefined : projectIssuesData?.issues?.length;

    // const handleChangeVariant = (event: React.ChangeEvent<{ value: unknown }>) => {
    //     setVariant(event.target.value as number);
    // };

    const versions: Maybe<ProjectFileVersion>[] = models?.find(pf => pf.id === selectedModel)
        ?.projectFileVersions as Maybe<ProjectFileVersion>[];

    const variants: Maybe<ProjectFileVersionViewable>[] = versions
        ?.find(v => v?.id === version)
        ?.viewables?.filter(v => v?.viewableType === ViewableType.ThreeDimensional) as Maybe<
        ProjectFileVersionViewable
    >[];

    useEffect(() => {
        if (version === -1 && versions?.length > 0) {
            // @ts-ignore
            setVersionById(versions[0].id);
        }
        if (variant === -1 && variants?.length > 0) {
            // @ts-ignore
            setVariantById(variants[0].id);
        }
    }, [selectedModel, versions, variants]);

    useEffect(() => {
        setVersion(-1);
        setVariant(-1);
        setSelectedModel(modelId as number);
    }, [modelId]);

    return (
        <Box className={classes.root}>
            <FormGroup className={classes.formGroup}>
                <FormControlLabel
                    label="Datei:"
                    labelPlacement="start"
                    className={classes.formControlLabel}
                    control={
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                margin="dense"
                                displayEmpty
                                value={selectedModel}
                                onChange={handleChangeIfc}
                                className={classes.select}
                            >
                                <MenuItem dense value={-1} disabled>
                                    Auswählen
                                </MenuItem>
                                {models?.map((value: ProjectFile, index: number) => (
                                    <MenuItem key={index} dense value={value.id as number}>
                                        {value.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    }
                />

                <FormControlLabel
                    label="Version:"
                    labelPlacement="start"
                    className={classes.formControlLabel}
                    control={
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                margin="dense"
                                displayEmpty
                                value={version}
                                onChange={handleChangeVersion}
                                className={classes.select}
                            >
                                <MenuItem dense value={-1} disabled>
                                    Auswählen
                                </MenuItem>
                                {versions?.map((value: Maybe<ProjectFileVersion>, index: number) => (
                                    <MenuItem
                                        key={index}
                                        dense
                                        value={value?.id as number}
                                    >{`V${value?.version}`}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    }
                />

                {!hideViewableSelector && (
                    <FormControlLabel
                        label="Variante:"
                        labelPlacement="start"
                        className={classes.formControlLabel}
                        control={
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                    margin="dense"
                                    displayEmpty
                                    value={variant}
                                    onChange={handleChangeVariant}
                                    className={classes.select}
                                >
                                    <MenuItem dense value={-1} disabled>
                                        Auswählen
                                    </MenuItem>
                                    {variants?.map((value: Maybe<ProjectFileVersionViewable>, index: number) => (
                                        <MenuItem
                                            key={index}
                                            dense
                                            value={value?.id as number}
                                        >{`${value?.name}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        }
                    />
                )}

                <IssueButton modelId={selectedModel} versionId={version} count={count} />
            </FormGroup>
        </Box>
    );
};

export default VersionSelector;

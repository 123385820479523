import { Box, createStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import ForgeViewer from "iolabs-react-forge-viewer";
import { isArray, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useKeycloak } from "react-keycloak";
import { viewerProxy } from "../../api/viewer/client";
import { IViewerProxyResponse } from "../../api/viewer/types";
import config from "../../config/config";

import { usePanelIsolate, usePanelSpeed, usePanelSurrounding, usePanelTurntable } from "../../redux/dashboard";
import {
    ExtensionID as SyncViewNavigationExtensionID,
    register as registerSyncViewNavigationExtension,
} from "./extensions/Viewing.Extensions.SyncViewNavigation/Viewing.Extensions.SyncViewNavigationExtension";
import {
    ExtensionID as TurnTableExtensionID,
    register as registerTurnTableExtension,
} from "./extensions/Viewing.Extensions.TurnTable/Viewing.Extensions.TurnTableExtension";
import {
    ExtensionID as SurroundingExtensionID,
    register as registerSurroundingExtension,
} from "./extensions/Viewing.Extensions.Surrounding/Viewing.Extensions.SurroundingExtension";
import { Emea } from "./types/type";
import { CreateIssueMutationVariables, Issue } from "../../graphql/generated/graphql";
import IssuableComponent from "../Issues/IssuableComponent";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            position: "relative",
            zIndex: 0,
            height: "100%",
        },
        box: {
            position: "relative",
            height: "100%",
            maxHeight: "100%",
            overflowX: "hidden",
            "& .viewcube": {
                transform: "scale(0.6)",
            },
        },
        skeleton: {
            height: "100%",
        },
        hiddenControls: {
            "& .adsk-viewing-viewer .adsk-toolbar": {
                display: "none !important",
            },
        },
    }),
);

type ViewerState = {
    api: string;
    urn: string;
    isEmea: boolean;
};

interface IViewerProps {
    urn: string;
    selectionIds?: string[];
    showOnlyIds?: string[];
    disableFetchLatest?: boolean;
    projectId: number;
    projectFileVersionId: number;
    viewableGuid?: string;
}

const Viewer: React.FC<IViewerProps> = (props: IViewerProps) => {
    const { urn, selectionIds, disableFetchLatest, projectId, projectFileVersionId, viewableGuid, showOnlyIds } = props;

    const classes = useStyles();
    const [viewer, setViewer] = useState<any>();
    const [unmounted, setUnmounted] = useState<boolean>(false);
    const [objectTreeCreated, setObjectTreeCreated] = useState<boolean>(false);
    const [viewable, setViewable] = useState<any>();
    const [viewables, setViewables] = useState<any[]>();
    const { keycloak } = useKeycloak();
    const turnTableEnabled = usePanelTurntable();
    const turnTableSpeed = usePanelSpeed();
    const isolationEnabled = usePanelIsolate();
    const surroundingEnabled = usePanelSurrounding();
    const [viewerState, setViewerState] = useState<ViewerState | null>(null);
    const [pushpinHandle, setPushpinHandle] = useState<any>(null);

    const [issues, setIssues] = useState<any>([]);

    useEffect(() => {
        if (keycloak.token) {
            viewerProxy(keycloak.token, urn, "e3943204-af6e-4341-b595-c85173d1222b", !disableFetchLatest)
                .then((response: IViewerProxyResponse) => {
                    setViewerState({
                        api: response?.api,
                        urn: response?.urn,
                        isEmea: response?.isEmea,
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [urn, keycloak]);

    useEffect(() => {
        if (viewer) {
            const ext = viewer.getExtension(TurnTableExtensionID);
            if (ext) {
                ext.toggle(turnTableEnabled);
            }
        }
    }, [turnTableEnabled, viewer]);

    useEffect(() => {
        if (viewer) {
            const ext = viewer.getExtension(TurnTableExtensionID);
            if (ext) {
                ext.setSpeed(turnTableSpeed);
            }
        }
    }, [turnTableSpeed, viewer]);

    useEffect(() => {
        if (viewer) {
            const ext = viewer.getExtension(SurroundingExtensionID);
            if (ext) {
                ext.setSurrounding(surroundingEnabled);
            }
        }
    }, [surroundingEnabled, viewer]);

    useEffect(() => {
        if (viewer) {
            if (selectionIds) {
                var ids = selectionIds.map(id => parseInt(id));
                renderSelection(ids);
                if (isolationEnabled) {
                    renderIsolation(ids);
                } else {
                    renderIsolation([]);
                }
            }
        }
    }, [viewer, selectionIds, isolationEnabled]);

    useEffect(() => {
        if (viewer) {
            if (objectTreeCreated) {
                if (showOnlyIds) {
                    showIdsOnly(showOnlyIds.map(id => parseInt(id)));
                } else {
                    showAll();
                }
            }
        }
    }, [viewer, showOnlyIds, objectTreeCreated]);

    useEffect(() => {
        if (!unmounted && viewables) {
            const selectedViewable = viewables?.find(v => v.findByGuid(viewableGuid) != null);
            if (!selectedViewable) {
                console.error("Unable to find selected viewable");
                if (viewables) {
                    setViewable(viewables[0]);
                }
            } else {
                setViewable(selectedViewable);
            }
        }
    }, [viewables, viewableGuid, unmounted]);

    const handleDocumentLoaded = (doc: any, loadedViewables: any[]) => {
        setViewables(loadedViewables);
        // if (!unmounted) {
        //     if (viewables.length === 0) {
        //         console.error("Document contains no viewables.");
        //     } else {
        //         setViewable(viewables[0]);
        //     }
        // }
    };

    const handleViewerLoaded = (viewer: Autodesk.Viewing.Viewer3D) => {
        if (!unmounted) {
            setViewer(viewer);
        }
        viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, () => {
            setObjectTreeCreated(true);
        });
    };

    const handleDocumentError = (viewer: any, error: any) => {
        console.error("Error loading a document.");
    };

    useEffect(() => {
        if (viewer && pushpinHandle) {
            pushpinHandle.removeAllItems();
            if (issues) {
                issues.forEach((issue: Issue) => {
                    try {
                        const issueData = JSON.parse(
                            issue?.issuesInExternalSystems?.find(ies => ies?.externalSystem?.code === "forge")
                                ?.position as string,
                        );
                        const issueStatus = issue?.status?.statusesInExternalSystems?.find(
                            ies => ies?.externalSystem?.code === "forge",
                        )?.externalId as string;

                        // var issueAttributes = issue.attributes;
                        // var pushpinAttributes = issue.attributes.pushpin_attributes;
                        // Notice the last rendering condition, which will enforce rendering the pushpin on the current sheet.
                        // We simply compare the issue sheet metadata against the current sheet.
                        if (
                            issueData /*&& issueAttributes.sheet_metadata &&
                    issueAttributes.sheet_metadata.sheetGuid === viewerApp.selectedItem.guid()*/
                        ) {
                            pushpinHandle.pushPinManager.createItem(
                                {
                                    id: issue.id, // The issue ID.
                                    label: issue.name, // The value displayed when you select the pushpin.
                                    // The shape and color of the pushpin, in the following format: ``type-status`` (e.g., ``issues-open``).
                                    status: `issues-${issueStatus}` /*issue.type && issueData.status.indexOf(issue.type) === -1 ?
                                `${issue.type}-${issueData.status}` : issueData.status*/,
                                    position: issueData.PushpinAttributes.location, // The x, y, z coordinates of the pushpin.
                                    type: "issues", // The issue type.
                                    objectId: issueData.PushpinAttributes.object_id, // (Only for 3D models) The object the pushpin is situated on.
                                    viewerState: issueData.PushpinAttributes.viewer_state, // The current viewer state. For example, angle, camera, zoom.
                                },
                                true,
                            );
                        } // if
                    } catch (e) {
                        console.error("Unable to render issue", e);
                    }
                });
            }
        }
    }, [issues, viewer, pushpinHandle]);

    const handleModelLoaded = (viewer: Autodesk.Viewing.Viewer3D, model: Autodesk.Viewing.Model) => {
        // if (!unmounted) {
        //     setModelLoaded(true);
        // }
        // if (onModelLoaded) {
        //     onModelLoaded(model);
        // }
        viewer.loadExtension(TurnTableExtensionID, {});
        viewer.loadExtension(SyncViewNavigationExtensionID, {});
        viewer.loadExtension(SurroundingExtensionID, {}).then((extension: any) => {
            extension.setSurrounding(surroundingEnabled);
        });

        const pushpinOptions = {
            hideIssuesButton: false,
            hideRfisButton: true,
            hideFieldIssuesButton: true,
        };

        viewer
            .loadExtension("Autodesk.BIM360.Extension.PushPin", pushpinOptions)
            .then(pushpinExtension => setPushpinHandle(pushpinExtension));
    };

    const handleModelError = (viewer: any, model: any) => {
        console.error("Error loading the model.");
    };

    const handleTokenRequested = (onAccessToken: any) => {
        onAccessToken(keycloak.token);
    };

    const handleViewerError = () => {
        console.error("Error loading viewer.");
    };

    const handleForgeScriptLoaded = () => {
        registerTurnTableExtension();
        registerSyncViewNavigationExtension();
        registerSurroundingExtension();
    };

    const renderSelection = (selectionIds: number[]) => {
        if (!isEmpty(selectionIds)) {
            console.log("XX selection", selectionIds);
            viewer.select(selectionIds);
        } else {
            viewer.clearSelection();
        }
    };

    const renderIsolation = (isolationIds: number[]) => {
        if (!isEmpty(isolationIds)) {
            viewer.isolate(isolationIds);
        } else {
            if (viewer.model) {
                viewer.showAll();
            }
        }
    };

    const showAll = () => {
        if (viewer.model && viewer.model.getData().instanceTree) {
            var dbToId = viewer.model.getData().instanceTree.nodeAccess.dbIdToIndex;
            for (var i = 0; i < viewer.model.getData().instanceTree.objectCount - 1; i++) {
                viewer.impl.visibilityManager.setNodeOff(dbToId[i], false);
            }
        }
    };

    const getLeafNodes = (model, dbIds) => {
        return new Promise((resolve, reject) => {
            try {
                const instanceTree = model.getData().instanceTree;
                dbIds = dbIds || instanceTree.getRootId();
                const dbIdArray = Array.isArray(dbIds) ? dbIds : [dbIds];
                let leafIds: any[] = [];
                const getLeafNodesRec = id => {
                    var childCount = 0;
                    instanceTree.enumNodeChildren(id, childId => {
                        getLeafNodesRec(childId);
                        ++childCount;
                    });

                    if (childCount == 0) {
                        leafIds.push(id);
                    }
                };

                for (var i = 0; i < dbIdArray.length; ++i) {
                    getLeafNodesRec(dbIdArray[i]);
                }
                return resolve(leafIds);
            } catch (ex) {
                return reject(ex);
            }
        });
    };

    const showIdsOnly = async ids => {
        if (viewer.model && isArray(ids)) {
            ids = await getLeafNodes(viewer.model, ids);
            for (var i = 0; i < viewer.model.getData().instanceTree.objectCount - 1; i++) {
                viewer.impl.visibilityManager.setNodeOff(i, !(ids.indexOf(i) >= 0));
            }
        }
    };

    const createIssueModifier = (): Partial<CreateIssueMutationVariables> => {
        const pushpinHandle = viewer.getExtension("Autodesk.BIM360.Extension.PushPin") as any;
        const issue = pushpinHandle.getItemById("0");

        var locationInfo = {
            sheetMetadata: {
                is3D: viewable.is3D(),
                sheetGuid: viewable.guid(),
                sheetName: viewable.name(),
            },
            pushpin_attributes: {
                // Data about the pushpin
                type: "TwoDVectorPushpin", // This is the only type currently available
                object_id: issue.objectId, // (Only for 3D models) The object the pushpin is situated on.
                location: issue.position, // The x, y, z coordinates of the pushpin.
                viewer_state: issue.viewerState, // The current viewer state. For example, angle, camera, zoom.
            },
        };

        var issueDiff: Partial<CreateIssueMutationVariables> = {
            projectId: projectId,
            projectFileVersionId: projectFileVersionId,
            externalSystemLocation: JSON.stringify(locationInfo),
            externalSystemCode: "forge",
        };
        return issueDiff;
    };

    const stopPinpointingpinpointIssue = () => {
        const pushpinHandle = viewer.getExtension("Autodesk.BIM360.Extension.PushPin") as any;
        pushpinHandle.pushPinManager.removeEventListener("pushpin.created");
        pushpinHandle.pushPinManager.removeEventListener("pushpin.modified");
        pushpinHandle.endCreateItem();
    };

    const pinpointIssue = (onUpdated: (partialData: Partial<CreateIssueMutationVariables>) => void) => {
        const pushpinHandle = viewer.getExtension("Autodesk.BIM360.Extension.PushPin") as any;
        pushpinHandle.startCreateItem({
            id: "0",
            label: "New",
            status: "open",
            // position: ""
            type: "issues",
            // objectId: "",
            // viewerState: "",
        });

        // Pushpin creation
        pushpinHandle.pushPinManager.addEventListener("pushpin.created", e => {
            if (e.value.itemData.id == "0") {
                pushpinHandle.endCreateItem();
                pushpinHandle.setDraggableById("0", true);

                onUpdated(createIssueModifier());
            }
        });

        // Pushpin dragging
        pushpinHandle.pushPinManager.addEventListener("pushpin.modified", e => {
            pushpinHandle.setDraggableById("0", true);
            onUpdated(createIssueModifier());
        });
    };

    return (
        <Box className={classes.root}>
            {viewable ? (
                <>
                    <IssuableComponent
                        id={"viewer"}
                        collectIssueData={pinpointIssue}
                        renderIssues={setIssues}
                        stopCollecting={stopPinpointingpinpointIssue}
                    />
                </>
            ) : null}
            {viewerState ? (
                <Box className={classes.box}>
                    <ForgeViewer
                        version="7.16"
                        urn={viewerState.urn}
                        api={viewerState.isEmea ? Emea.eu : Emea.default}
                        view={viewable}
                        query={{ type: "geometry" }}
                        headless={false}
                        proxy={config.api.forgeProxyUrl}
                        onViewerError={handleViewerError}
                        onTokenRequest={handleTokenRequested}
                        onDocumentLoad={handleDocumentLoaded}
                        onDocumentError={handleDocumentError}
                        onModelLoad={handleModelLoaded}
                        onModelError={handleModelError}
                        onViewerLoad={handleViewerLoaded}
                        onScriptLoaded={handleForgeScriptLoaded}
                    />
                </Box>
            ) : (
                <Box display="flex" flexDirection="column" alignItems="center" height="100%">
                    <Skeleton variant="rect" width="100%" className={classes.skeleton} />
                </Box>
            )}
        </Box>
    );
};

export default Viewer;

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** 
 * The `Date` scalar type represents a year, month and day in accordance with the
   * [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard.
 */
  Date: any,
  /** Int16 */
  Int16: any,
  /** 
 * The `DateTime` scalar type represents a date and time. `DateTime` expects
   * timestamps to be formatted in accordance with the
   * [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard.
 */
  DateTime: any,
  /** 
 * The `DateTimeOffset` scalar type represents a date, time and offset from UTC.
   * `DateTimeOffset` expects timestamps to be formatted in accordance with the
   * [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard.
 */
  DateTimeOffset: any,
  Decimal: any,
  /** The `Milliseconds` scalar type represents a period of time represented as the total number of milliseconds. */
  Milliseconds: any,
  /** The `Seconds` scalar type represents a period of time represented as the total number of seconds. */
  Seconds: any,
};




export type AttributeInDashboard = {
  attribute?: Maybe<ProjectAttribute>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  projectDashboard?: Maybe<ProjectDashboard>,
  sortOrder?: Maybe<Scalars['Int']>,
};

export type AttributeRole = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  elementAttribute?: Maybe<ElementAttribute>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  role?: Maybe<Role>,
  roleType?: Maybe<AttributeRoleType>,
};

export enum AttributeRoleType {
  View = 'VIEW',
  Edit = 'EDIT'
}

export type AttributeType = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  dataType?: Maybe<DataType>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  unit?: Maybe<Scalars['String']>,
};

export type Category = {
  attributes?: Maybe<Array<Maybe<ComparedAttribute>>>,
  description: Scalars['String'],
  name: Scalars['String'],
  type?: Maybe<CategoryType>,
};

export enum CategoryType {
  Normal = 'NORMAL',
  Price = 'PRICE'
}

export type ComparedAttribute = {
  attributeType?: Maybe<AttributeType>,
  children?: Maybe<Array<Maybe<ComparedAttribute>>>,
  code: Scalars['String'],
  comparedValues?: Maybe<Array<Maybe<ComparerValue>>>,
  description?: Maybe<Scalars['String']>,
  name: Scalars['String'],
};

export type CompareResult = {
  categories?: Maybe<Array<Maybe<Category>>>,
  comparedViewables?: Maybe<Array<Maybe<ProjectFileVersionViewable>>>,
};

export type ComparerValue = {
  code: Scalars['String'],
  instances?: Maybe<Array<Maybe<Scalars['String']>>>,
  value?: Maybe<Scalars['String']>,
};

export enum ComparisonGraph {
  Contains = 'contains',
  EndsWith = 'endsWith',
  Equal = 'equal',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  NotIn = 'notIn',
  In = 'in',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  Like = 'like',
  StartsWith = 'startsWith'
}

export enum Connector {
  /** 
 * AND
   * OR
 */
  And = 'and',
  Or = 'or'
}

export type Currency = {
  alpha3Code?: Maybe<Scalars['String']>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
};

export enum DashboardType {
  IstSoll = 'IST_SOLL',
  SingleColumn = 'SINGLE_COLUMN'
}

export type DataModel = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  elements?: Maybe<Array<Maybe<Element>>>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  relationTrees?: Maybe<Array<Maybe<RelationTree>>>,
};


export type DataModelElementsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type DataModelRelationTreesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export enum DataType {
  String = 'STRING',
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  DateTime = 'DATE_TIME',
  Int = 'INT',
  Double = 'DOUBLE',
  Link = 'LINK'
}





export type Element = {
  attributes?: Maybe<Array<Maybe<ElementAttribute>>>,
  category?: Maybe<ElementCategory>,
  /** 
 * Full element code
   * 
   * Containing full category structure code, only for performance
 */
  code?: Maybe<Scalars['String']>,
  /** 
 * Element code suffix
   * 
   * Suffix will be prepended all category structure codes
 */
  codeSuffix?: Maybe<Scalars['String']>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  dataModel?: Maybe<DataModel>,
  elementPrices?: Maybe<Array<Maybe<ElementPrice>>>,
  elementTag?: Maybe<ElementTagEnum>,
  id?: Maybe<Scalars['Int']>,
  instances?: Maybe<Array<Maybe<Instance>>>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  /** 
 * Element name
   * 
   * Name of the element
 */
  name?: Maybe<Scalars['String']>,
  preferedPriceSource?: Maybe<PriceSource>,
  priceRelevantAttribute?: Maybe<ElementAttribute>,
  priority?: Maybe<Scalars['Int']>,
};


export type ElementAttributesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type ElementElementPricesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type ElementInstancesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type ElementAttribute = {
  active?: Maybe<Scalars['Boolean']>,
  attributeRoles?: Maybe<Array<Maybe<AttributeRole>>>,
  attributeType?: Maybe<AttributeType>,
  /** 
 * Element attribute code
   * 
   * Alphanumeric attribute code
 */
  code?: Maybe<Scalars['String']>,
  color?: Maybe<Scalars['String']>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  element?: Maybe<Element>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  multiplicityLimit?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  required?: Maybe<Scalars['Boolean']>,
  sortOrder?: Maybe<Scalars['Int']>,
};


export type ElementAttributeAttributeRolesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type ElementAttributeValue = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  elementAttribute?: Maybe<ElementAttribute>,
  elementInstance?: Maybe<Instance>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['String']>,
};

export type ElementCategory = {
  childCategories?: Maybe<Array<Maybe<ElementCategory>>>,
  code?: Maybe<Scalars['String']>,
  codeSuffix?: Maybe<Scalars['String']>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  element?: Maybe<Array<Maybe<Element>>>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  parentCategory?: Maybe<ElementCategory>,
  sortOrder?: Maybe<Scalars['Int']>,
};


export type ElementCategoryChildCategoriesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type ElementCategoryElementArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type ElementPrice = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  currency?: Maybe<Currency>,
  element?: Maybe<Element>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Valid flag
   * 
   * Simple representation of validity
 */
  isValid?: Maybe<Scalars['Boolean']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  previousPrice?: Maybe<ElementPrice>,
  priceSource?: Maybe<PriceSource>,
  /** 
 * Valid from
   * 
   * The begining of the valid period
 */
  validFrom?: Maybe<Scalars['Date']>,
  /** 
 * Valid to
   * 
   * The end of the valid period, null if no end of the valid period
 */
  validTo?: Maybe<Scalars['Date']>,
  value?: Maybe<Scalars['Int']>,
};

export enum ElementTagEnum {
  Noticluded = 'NOTICLUDED',
  Normal = 'NORMAL',
  Uncathegorised = 'UNCATHEGORISED'
}

export type ExternalSystem = {
  code?: Maybe<Scalars['String']>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
};

export type FileInExternalSystem = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  /** relative folder in external system */
  externalFolder?: Maybe<Scalars['String']>,
  /** Forge: FileId */
  externalId?: Maybe<Scalars['String']>,
  externalSystem?: Maybe<ExternalSystem>,
  /** 
 * External System code
   * 
   * External System code
 */
  externalSystemCode?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  projectFile?: Maybe<ProjectFile>,
};

export type FileType = {
  code?: Maybe<Scalars['String']>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  /** TODO use type-enum? */
  isModel?: Maybe<Scalars['Boolean']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
};

export type FileVersionInExternalSystem = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  /** Forge: URN */
  externalId?: Maybe<Scalars['String']>,
  externalSystem?: Maybe<ExternalSystem>,
  /** 
 * External System code
   * 
   * External System code
 */
  externalSystemCode?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  projectFileVersion?: Maybe<ProjectFileVersion>,
};

export type Filter = {
  code: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  items?: Maybe<Array<Maybe<FilterItem>>>,
  name?: Maybe<Scalars['String']>,
};

export type FilterInput = {
  code: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  items?: Maybe<Array<Maybe<FilterItemInput>>>,
};

/** Represents single item of one filter */
export type FilterItem = {
  code: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  instancesPerFileVersionViewable?: Maybe<Array<Maybe<ProjectFileVersionViewableInstances>>>,
  name?: Maybe<Scalars['String']>,
};

/** Represents single item of one filter */
export type FilterItemInput = {
  code: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
};

export type Instance = {
  attributeValues?: Maybe<Array<Maybe<ElementAttributeValue>>>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  element?: Maybe<Element>,
  followingInstance?: Maybe<Instance>,
  globalId?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  instanceViewerIds?: Maybe<Array<Maybe<InstanceViewerId>>>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  previousInstance?: Maybe<Instance>,
  projectFileVersion?: Maybe<ProjectFileVersion>,
  projectFileVersionViewable?: Maybe<ProjectFileVersionViewable>,
  relationsAsInstance1?: Maybe<Array<Maybe<InstanceRelation>>>,
  relationsAsInstance2?: Maybe<Array<Maybe<InstanceRelation>>>,
};


export type InstanceAttributeValuesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type InstanceInstanceViewerIdsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type InstanceRelationsAsInstance1Args = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type InstanceRelationsAsInstance2Args = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type InstanceRelation = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  instance1?: Maybe<Instance>,
  instance2?: Maybe<Instance>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  relationDefinition?: Maybe<RelationDefinition>,
};

export type InstanceViewerId = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  externalId?: Maybe<Scalars['String']>,
  externalSystem?: Maybe<ExternalSystem>,
  id?: Maybe<Scalars['Int']>,
  instance?: Maybe<Instance>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
};


export type Issue = {
  /** 
 * Assigned to
   * 
   * User the issue is assigned to
 */
  assignedTo?: Maybe<User>,
  /** 
 * Issue cause
   * 
   * Cause of the issue
 */
  cause?: Maybe<IssueCause>,
  /** Unique issue code */
  code?: Maybe<Scalars['String']>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  /** Issue description */
  description?: Maybe<Scalars['String']>,
  /** Issue due date */
  dueDate?: Maybe<Scalars['Date']>,
  /** Numerical identifier */
  id?: Maybe<Scalars['Int']>,
  /** 
 * Issues in external system
   * 
   * Issues specific data in external system
 */
  issuesInExternalSystems?: Maybe<Array<Maybe<IssueInExternalSystem>>>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  /** 
 * Location description
   * 
   * Text description of the issue location
 */
  locationDescription?: Maybe<Scalars['String']>,
  /** Issue title */
  name?: Maybe<Scalars['String']>,
  /** 
 * Owner
   * 
   * User who owns the issue
 */
  owner?: Maybe<User>,
  /** 
 * Related project
   * 
   * Project where the issue is
 */
  project?: Maybe<Project>,
  /** 
 * Related project file version
   * 
   * File version where the issue is
 */
  projectFileVersion?: Maybe<ProjectFileVersion>,
  /** 
 * Reporter
   * 
   * User the issue was reported by
 */
  reporter?: Maybe<User>,
  /** 
 * Issue status
   * 
   * Current status of the issue
 */
  status?: Maybe<IssueStatus>,
  /** 
 * Issue type
   * 
   * Type of the issue
 */
  type?: Maybe<IssueType>,
};


export type IssueIssuesInExternalSystemsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type IssueCause = {
  /** 
 * Causes in external system
   * 
   * Issues cause specific data in external system
 */
  causesInExternalSystems?: Maybe<Array<Maybe<IssueCauseInExternalSystem>>>,
  /** Child cause */
  children?: Maybe<Array<Maybe<IssueCause>>>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  /** Cause description */
  description?: Maybe<Scalars['String']>,
  /** Numerical identifier */
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  /** Cause name */
  name?: Maybe<Scalars['String']>,
  /** Parent cause */
  parent?: Maybe<IssueCause>,
  /** Project */
  project?: Maybe<Project>,
};


export type IssueCauseCausesInExternalSystemsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type IssueCauseChildrenArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type IssueCauseInExternalSystem = {
  /** Related cause */
  cause?: Maybe<IssueCause>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  /** Identifier in external system */
  externalId?: Maybe<Scalars['String']>,
  /** External system */
  externalSystem?: Maybe<ExternalSystem>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
};

export type IssueInExternalSystem = {
  /** Unique issue code */
  code?: Maybe<Scalars['String']>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  /** Identifier in external system */
  externalId?: Maybe<Scalars['String']>,
  /** External system */
  externalSystem?: Maybe<ExternalSystem>,
  id?: Maybe<Scalars['Int']>,
  /** Related issue */
  issue?: Maybe<Issue>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  /** Position of the issue according to external system */
  position?: Maybe<Scalars['String']>,
};

export type IssueInExternalSystemInput = {
  externalSystemCode: Scalars['String'],
  location?: Maybe<Scalars['String']>,
};

export type IssueInput = {
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  dueDate?: Maybe<Scalars['DateTime']>,
  locationDescription?: Maybe<Scalars['String']>,
  projectId: Scalars['Int'],
  projectFileVersionId?: Maybe<Scalars['Int']>,
  statusId: Scalars['Int'],
  typeId: Scalars['Int'],
  causeId?: Maybe<Scalars['Int']>,
  assignedToId?: Maybe<Scalars['Int']>,
  reporterId?: Maybe<Scalars['Int']>,
  ownerId?: Maybe<Scalars['Int']>,
  externalSystemData: IssueInExternalSystemInput,
};

export type IssueStatus = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  /** Status description */
  description?: Maybe<Scalars['String']>,
  /** 
 * Higlight color
   * 
   * Color used for some kinds of highlighting
 */
  highlighColor?: Maybe<Scalars['String']>,
  /** Numerical identifier */
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  /** Status name */
  name?: Maybe<Scalars['String']>,
  /** 
 * Status in external system
   * 
   * Issues Status specific data in external system
 */
  statusesInExternalSystems?: Maybe<Array<Maybe<IssueStatusInExternalSystem>>>,
};


export type IssueStatusStatusesInExternalSystemsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type IssueStatusInExternalSystem = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  /** Identifier in external system */
  externalId?: Maybe<Scalars['String']>,
  /** External system */
  externalSystem?: Maybe<ExternalSystem>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  /** Related status */
  status?: Maybe<IssueStatus>,
};

export type IssueType = {
  /** Child types */
  children?: Maybe<Array<Maybe<IssueType>>>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  /** Type description */
  description?: Maybe<Scalars['String']>,
  /** Numerical identifier */
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  /** Type name */
  name?: Maybe<Scalars['String']>,
  /** Parent type */
  parent?: Maybe<IssueType>,
  /** Project */
  project?: Maybe<Project>,
  /** 
 * Types in external system
   * 
   * Issues type specific data in external system
 */
  typesInExternalSystems?: Maybe<Array<Maybe<IssueTypeInExternalSystem>>>,
};


export type IssueTypeChildrenArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type IssueTypeTypesInExternalSystemsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type IssueTypeInExternalSystem = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  /** Identifier in external system */
  externalId?: Maybe<Scalars['String']>,
  /** External system */
  externalSystem?: Maybe<ExternalSystem>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  /** Related type */
  type?: Maybe<IssueType>,
};

export type Language = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  iSO6391Code?: Maybe<Scalars['String']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  nativeName?: Maybe<Scalars['String']>,
};


export type Mutation = {
  createIssue?: Maybe<Issue>,
};


export type MutationCreateIssueArgs = {
  issue: IssueInput
};

export type OrderByGraph = {
  path: Scalars['String'],
  descending?: Maybe<Scalars['Boolean']>,
};

export type PriceSource = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  priceType?: Maybe<PriceType>,
};

export enum PriceType {
  Total = 'TOTAL',
  Unit = 'UNIT'
}

/** 
 * Project entity
 * 
 * Represents single project in BIM Tools
 */
export type Project = {
  /** 
 * Active flag
   * 
   * True when the project is actively maintained
 */
  active?: Maybe<Scalars['Boolean']>,
  /** Collection of project attributes */
  attributes?: Maybe<Array<Maybe<ProjectAttribute>>>,
  /** Unique project code */
  code?: Maybe<Scalars['String']>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  /** Project dashboard */
  dashboards?: Maybe<Array<Maybe<ProjectDashboard>>>,
  dataModel?: Maybe<DataModel>,
  /** 
 * Default viewer viewport
   * 
   * The viewer viewport which is used when the model is loaded into viewer
 */
  defaultViewerViewpoint?: Maybe<ViewerViewpoint>,
  /** Description of the project */
  description?: Maybe<Scalars['String']>,
  /** Numeric identifier */
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  /** Last update time */
  lastUpdate?: Maybe<Scalars['Date']>,
  /** Main model file */
  mainModelFile?: Maybe<ProjectFile>,
  /** 
 * Name of the project
   * 
   * Textual name of the project
 */
  name?: Maybe<Scalars['String']>,
  /** 
 * Receiver of project specific notification
   * 
   * Email address of the receiver
 */
  notificationReceiver?: Maybe<Scalars['String']>,
  /** Collection of project files */
  projectFiles?: Maybe<Array<Maybe<ProjectFile>>>,
  /** 
 * Definition of project bound to external system
   * 
   * External system specific attributes
 */
  projectInExternalSystems?: Maybe<Array<Maybe<ProjectsInExternalSystem>>>,
  services?: Maybe<Array<Maybe<ProjectService>>>,
  /** Project State */
  status?: Maybe<ProjectStatus>,
  /** Collection of viewer viewports */
  viewerViewpoints?: Maybe<Array<Maybe<ViewerViewpoint>>>,
};


/** 
 * Project entity
 * 
 * Represents single project in BIM Tools
 */
export type ProjectAttributesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** 
 * Project entity
 * 
 * Represents single project in BIM Tools
 */
export type ProjectDashboardsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** 
 * Project entity
 * 
 * Represents single project in BIM Tools
 */
export type ProjectProjectFilesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** 
 * Project entity
 * 
 * Represents single project in BIM Tools
 */
export type ProjectProjectInExternalSystemsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** 
 * Project entity
 * 
 * Represents single project in BIM Tools
 */
export type ProjectServicesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** 
 * Project entity
 * 
 * Represents single project in BIM Tools
 */
export type ProjectViewerViewpointsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type ProjectAttribute = {
  attributeType?: Maybe<AttributeType>,
  childAttributes?: Maybe<Array<Maybe<ProjectAttribute>>>,
  /** 
 * Unique alphanumeric Code
   * 
   * Code should be unique across project and  should not contain any special characters
 */
  code?: Maybe<Scalars['String']>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  /** Attribute description */
  description?: Maybe<Scalars['String']>,
  /** 
 * Higlight color
   * 
   * Color used for some kinds of highlighting
 */
  highlighColor?: Maybe<Scalars['String']>,
  /** Numerical identifier */
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  /** 
 * Attribute name
   * 
   * Human readable attribute name
 */
  name?: Maybe<Scalars['String']>,
  parentAttribute?: Maybe<ProjectAttribute>,
  project?: Maybe<Project>,
  projectAttributeGroup?: Maybe<ProjectAttributeGroup>,
  projectFile?: Maybe<ProjectFile>,
  /** 
 * Value
   * 
   * Value, which is precalculated
 */
  value?: Maybe<Scalars['String']>,
  /** 
 * value getter
   * 
   * Method how to calculated this attribute value
 */
  valueGetter?: Maybe<Scalars['String']>,
};


export type ProjectAttributeChildAttributesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type ProjectAttributeGroup = {
  attributes?: Maybe<Array<Maybe<ProjectAttribute>>>,
  /** 
 * Unique alphanumeric Code
   * 
   * Code should be unique across project and  should not contain any special characters
 */
  code?: Maybe<Scalars['String']>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  /** Attribute group description */
  description?: Maybe<Scalars['String']>,
  /** Numerical identifier */
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  /** 
 * Attribute group name
   * 
   * Human readable attribute group name
 */
  name?: Maybe<Scalars['String']>,
};


export type ProjectAttributeGroupAttributesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type ProjectAttributeValue = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  projectAttribute?: Maybe<ProjectAttribute>,
  projectFile?: Maybe<ProjectFile>,
  projectFileVersion?: Maybe<ProjectFileVersion>,
  value?: Maybe<Scalars['String']>,
};

export type ProjectDashboard = {
  attributes?: Maybe<Array<Maybe<AttributeInDashboard>>>,
  code?: Maybe<Scalars['String']>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  dashboardType?: Maybe<DashboardType>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  includeLastUpdate?: Maybe<Scalars['Boolean']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  project?: Maybe<Project>,
};


export type ProjectDashboardAttributesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type ProjectFile = {
  adapter?: Maybe<Scalars['String']>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  /** 
 * Real file name
   * 
   * File name
 */
  fileName?: Maybe<Scalars['String']>,
  filesInExternalSystem?: Maybe<Array<Maybe<FileInExternalSystem>>>,
  fileType?: Maybe<FileType>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  /** 
 * Last version
   * 
   * Last version of the file
 */
  lastVersion?: Maybe<ProjectFileVersion>,
  /** 
 * Custom display name which override file name
   * 
   * Display name
 */
  name?: Maybe<Scalars['String']>,
  priority?: Maybe<Scalars['Int']>,
  /** Project which the file belongs to */
  project?: Maybe<Project>,
  projectAttributes?: Maybe<Array<Maybe<ProjectAttribute>>>,
  /** Versions of the file */
  projectFileVersions?: Maybe<Array<Maybe<ProjectFileVersion>>>,
  viewerViewpoint?: Maybe<ViewerViewpoint>,
};


export type ProjectFileFilesInExternalSystemArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type ProjectFileProjectAttributesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type ProjectFileProjectFileVersionsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type ProjectFileVersion = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  fileVersionsInExternalSystem?: Maybe<Array<Maybe<FileVersionInExternalSystem>>>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Valid flag
   * 
   * Simple representation of validity
 */
  isValid?: Maybe<Scalars['Boolean']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  projectFile?: Maybe<ProjectFile>,
  /** 
 * Valid from
   * 
   * The begining of the valid period
 */
  validFrom?: Maybe<Scalars['Date']>,
  /** 
 * Valid to
   * 
   * The end of the valid period, null if no end of the valid period
 */
  validTo?: Maybe<Scalars['Date']>,
  version?: Maybe<Scalars['Int']>,
  /** Viewables of the version */
  viewables?: Maybe<Array<Maybe<ProjectFileVersionViewable>>>,
};


export type ProjectFileVersionFileVersionsInExternalSystemArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


export type ProjectFileVersionViewablesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type ProjectFileVersionViewable = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Valid flag
   * 
   * Simple representation of validity
 */
  isValid?: Maybe<Scalars['Boolean']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  projectFileVersion?: Maybe<ProjectFileVersion>,
  /** 
 * Valid from
   * 
   * The begining of the valid period
 */
  validFrom?: Maybe<Scalars['Date']>,
  /** 
 * Valid to
   * 
   * The end of the valid period, null if no end of the valid period
 */
  validTo?: Maybe<Scalars['Date']>,
  viewableInExternalSystem?: Maybe<Array<Maybe<ProjectFileVersionViewableInExternalSystem>>>,
  viewableType?: Maybe<ViewableType>,
};


export type ProjectFileVersionViewableViewableInExternalSystemArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type ProjectFileVersionViewableInExternalSystem = {
  /** Forge: svf */
  externalId?: Maybe<Scalars['String']>,
  externalSystem?: Maybe<ExternalSystem>,
  id?: Maybe<Scalars['Int']>,
  viewable?: Maybe<ProjectFileVersionViewable>,
};

/** Represents instance by filter and project file version viewable */
export type ProjectFileVersionViewableInstances = {
  instances?: Maybe<Array<Maybe<Scalars['String']>>>,
  viewable?: Maybe<ProjectFileVersionViewable>,
};

export type ProjectService = {
  allowed?: Maybe<Scalars['Boolean']>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  /** 
 * Services settings
   * 
   * Allowed services for the project
 */
  project?: Maybe<Project>,
  service?: Maybe<Service>,
  /** Sorting order */
  sortOrder?: Maybe<Scalars['Int']>,
};

export type ProjectsInExternalSystem = {
  /** Additional external system specific data */
  additionalData?: Maybe<Array<Maybe<Scalars['String']>>>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  /** Forge: ProjectId */
  externalId?: Maybe<Scalars['String']>,
  externalSystem?: Maybe<ExternalSystem>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  project?: Maybe<Project>,
  properFolderId?: Maybe<Scalars['String']>,
  tempFolderId?: Maybe<Scalars['String']>,
};

export enum ProjectStatus {
  UpToDate = 'UP_TO_DATE',
  Updating = 'UPDATING'
}

/** Basic Queries */
export type Query = {
  /** Get list of AttributeInDashboard */
  attributeInDashboards?: Maybe<Array<Maybe<AttributeInDashboard>>>,
  /** Get list of AttributeRole */
  attributeRoles?: Maybe<Array<Maybe<AttributeRole>>>,
  /** Get list of AttributeType */
  attributeTypes?: Maybe<Array<Maybe<AttributeType>>>,
  compareFilters?: Maybe<Array<Maybe<Filter>>>,
  compareVersions?: Maybe<CompareResult>,
  /** Get list of Currency */
  currencies?: Maybe<Array<Maybe<Currency>>>,
  /** Get list of DataModel */
  dataModels?: Maybe<Array<Maybe<DataModel>>>,
  /** Get list of ElementAttribute */
  elementAttributes?: Maybe<Array<Maybe<ElementAttribute>>>,
  /** Get list of ElementAttributeValue */
  elementAttributeValues?: Maybe<Array<Maybe<ElementAttributeValue>>>,
  /** Get list of ElementCategory */
  elementCategories?: Maybe<Array<Maybe<ElementCategory>>>,
  /** Get list of ElementPrice */
  elementPrices?: Maybe<Array<Maybe<ElementPrice>>>,
  /** Get list of Element */
  elements?: Maybe<Array<Maybe<Element>>>,
  /** Get list of ExternalSystem */
  externalSystems?: Maybe<Array<Maybe<ExternalSystem>>>,
  /** Get list of FileInExternalSystem */
  fileInExternalSystems?: Maybe<Array<Maybe<FileInExternalSystem>>>,
  /** Get list of FileType */
  fileTypes?: Maybe<Array<Maybe<FileType>>>,
  /** Get list of FileVersionInExternalSystem */
  fileVersionInExternalSystems?: Maybe<Array<Maybe<FileVersionInExternalSystem>>>,
  /** Get list of InstanceRelation */
  instanceRelations?: Maybe<Array<Maybe<InstanceRelation>>>,
  /** Get list of Instance */
  instances?: Maybe<Array<Maybe<Instance>>>,
  /** Get list of InstanceViewerId */
  instanceViewerIds?: Maybe<Array<Maybe<InstanceViewerId>>>,
  /** Get list of IssueCause */
  issueCauses?: Maybe<Array<Maybe<IssueCause>>>,
  /** Get list of IssueCauseInExternalSystem */
  issueCausesInExternalSystems?: Maybe<Array<Maybe<IssueCauseInExternalSystem>>>,
  /** Get list of Issue */
  issues?: Maybe<Array<Maybe<Issue>>>,
  /** Get list of IssueInExternalSystem */
  issuesInExternalSystems?: Maybe<Array<Maybe<IssueInExternalSystem>>>,
  /** Get list of IssueStatus */
  issueStatuses?: Maybe<Array<Maybe<IssueStatus>>>,
  /** Get list of IssueStatusInExternalSystem */
  issueStatusesInExternalSystems?: Maybe<Array<Maybe<IssueStatusInExternalSystem>>>,
  /** Get list of IssueType */
  issueTypes?: Maybe<Array<Maybe<IssueType>>>,
  /** Get list of IssueTypeInExternalSystem */
  issueTypesInExternalSystems?: Maybe<Array<Maybe<IssueTypeInExternalSystem>>>,
  /** Get list of Language */
  languages?: Maybe<Array<Maybe<Language>>>,
  /** Get list of PriceSource */
  priceSources?: Maybe<Array<Maybe<PriceSource>>>,
  /** Get list of ProjectAttributeGroup */
  projectAttributeGroups?: Maybe<Array<Maybe<ProjectAttributeGroup>>>,
  /** Get list of ProjectAttribute */
  projectAttributes?: Maybe<Array<Maybe<ProjectAttribute>>>,
  /** Get list of ProjectAttributeValue */
  projectAttributeValues?: Maybe<Array<Maybe<ProjectAttributeValue>>>,
  /** Get list of ProjectDashboard */
  projectDashboards?: Maybe<Array<Maybe<ProjectDashboard>>>,
  /** Get list of ProjectFile */
  projectFiles?: Maybe<Array<Maybe<ProjectFile>>>,
  /** Get list of ProjectFileVersion */
  projectFileVersions?: Maybe<Array<Maybe<ProjectFileVersion>>>,
  /** Get list of ProjectFileVersionViewable */
  projectFileVersionViewables?: Maybe<Array<Maybe<ProjectFileVersionViewable>>>,
  /** Get list of ProjectFileVersionViewableInExternalSystem */
  projectFileVersionViewablesInExternalSystem?: Maybe<Array<Maybe<ProjectFileVersionViewableInExternalSystem>>>,
  /** Get list of Project */
  projects?: Maybe<Array<Maybe<Project>>>,
  /** Get list of ProjectService */
  projectServices?: Maybe<Array<Maybe<ProjectService>>>,
  /** Get list of ProjectsInExternalSystem */
  projectsInExternalSystems?: Maybe<Array<Maybe<ProjectsInExternalSystem>>>,
  /** Get list of RelationDefinition */
  relationDefinitions?: Maybe<Array<Maybe<RelationDefinition>>>,
  /** Get list of RelationTree */
  relationTree?: Maybe<Array<Maybe<RelationTree>>>,
  /** Get list of RelationTreeNodeSet */
  relationTreeNodeSet?: Maybe<Array<Maybe<RelationTreeNodeSet>>>,
  /** Get list of RelationTreeRootNodeSet */
  relationTreeRootNodeSet?: Maybe<Array<Maybe<RelationTreeRootNodeSet>>>,
  /** Get list of Role */
  roles?: Maybe<Array<Maybe<Role>>>,
  /** Get list of Service */
  services?: Maybe<Array<Maybe<Service>>>,
  syncedIssues?: Maybe<Array<Maybe<Issue>>>,
  /** Get list of User */
  users?: Maybe<Array<Maybe<User>>>,
  /** Get list of Vector */
  vector?: Maybe<Array<Maybe<Vector>>>,
  /** Get list of ViewerViewpoint */
  viewerViewpoints?: Maybe<Array<Maybe<ViewerViewpoint>>>,
};


/** Basic Queries */
export type QueryAttributeInDashboardsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryAttributeRolesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryAttributeTypesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryCompareFiltersArgs = {
  projectFileVersionViewables?: Maybe<Array<Maybe<Scalars['Int']>>>,
  externalSystemCode: Scalars['String']
};


/** Basic Queries */
export type QueryCompareVersionsArgs = {
  projectFileVersionViewables?: Maybe<Array<Maybe<Scalars['Int']>>>,
  filters?: Maybe<Array<Maybe<FilterInput>>>,
  externalSystemCode: Scalars['String']
};


/** Basic Queries */
export type QueryCurrenciesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryDataModelsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryElementAttributesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryElementAttributeValuesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryElementCategoriesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryElementPricesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryElementsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryExternalSystemsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryFileInExternalSystemsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryFileTypesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryFileVersionInExternalSystemsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryInstanceRelationsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryInstancesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryInstanceViewerIdsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryIssueCausesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryIssueCausesInExternalSystemsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryIssuesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryIssuesInExternalSystemsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryIssueStatusesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryIssueStatusesInExternalSystemsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryIssueTypesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryIssueTypesInExternalSystemsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryLanguagesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryPriceSourcesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryProjectAttributeGroupsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryProjectAttributesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryProjectAttributeValuesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryProjectDashboardsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryProjectFilesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryProjectFileVersionsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryProjectFileVersionViewablesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryProjectFileVersionViewablesInExternalSystemArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryProjectsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryProjectServicesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryProjectsInExternalSystemsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryRelationDefinitionsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryRelationTreeArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryRelationTreeNodeSetArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryRelationTreeRootNodeSetArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryRolesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryServicesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QuerySyncedIssuesArgs = {
  projectFileVersionId?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryUsersArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryVectorArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};


/** Basic Queries */
export type QueryViewerViewpointsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type RelationDefinition = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  instance1Getter?: Maybe<Scalars['String']>,
  instance2Getter?: Maybe<Scalars['String']>,
  instanceRelations?: Maybe<Array<Maybe<InstanceRelation>>>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  type?: Maybe<RelationType>,
};


export type RelationDefinitionInstanceRelationsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type RelationTree = {
  code?: Maybe<Scalars['String']>,
  dataModel?: Maybe<DataModel>,
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  relationTreeRootNodeSets?: Maybe<Array<Maybe<RelationTreeRootNodeSet>>>,
};


export type RelationTreeRelationTreeRootNodeSetsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type RelationTreeNodeSet = {
  childNodeSets?: Maybe<Array<Maybe<RelationTreeNodeSet>>>,
  id?: Maybe<Scalars['Int']>,
  parentNodeSet?: Maybe<RelationTreeNodeSet>,
  relationDefinition?: Maybe<RelationDefinition>,
};


export type RelationTreeNodeSetChildNodeSetsArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type RelationTreeRootNodeSet = {
  id?: Maybe<Scalars['Int']>,
  relationTree?: Maybe<RelationTree>,
  relationTreeNodeSet?: Maybe<RelationTreeNodeSet>,
};

export enum RelationType {
  GeometryBased = 'GEOMETRY_BASED',
  MetaDataBased = 'META_DATA_BASED'
}

export type Role = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
};


export type Service = {
  activeByDefault?: Maybe<Scalars['Boolean']>,
  code?: Maybe<Scalars['String']>,
  color?: Maybe<Scalars['String']>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  /** Default Order */
  defaultOrder?: Maybe<Scalars['Int16']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  /** 
 * Presentation link
   * 
   * Link to the asset, where the service is presented (video, pdf, etc)
 */
  presentationLink?: Maybe<Scalars['String']>,
  type?: Maybe<ServiceType>,
};

export enum ServiceType {
  Main = 'MAIN',
  Project = 'PROJECT'
}

export enum StringComparison {
  CurrentCulture = 'CURRENT_CULTURE',
  CurrentCultureIgnoreCase = 'CURRENT_CULTURE_IGNORE_CASE',
  InvariantCulture = 'INVARIANT_CULTURE',
  InvariantCultureIgnoreCase = 'INVARIANT_CULTURE_IGNORE_CASE',
  Ordinal = 'ORDINAL',
  OrdinalIgnoreCase = 'ORDINAL_IGNORE_CASE'
}

export type User = {
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  roles?: Maybe<Array<Maybe<Role>>>,
};


export type UserRolesArgs = {
  id?: Maybe<Scalars['ID']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderBy?: Maybe<Array<Maybe<OrderByGraph>>>,
  where?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
  skip?: Maybe<Scalars['Int']>,
  take?: Maybe<Scalars['Int']>
};

export type Vector = {
  id?: Maybe<Scalars['Int']>,
  x?: Maybe<Scalars['Float']>,
  y?: Maybe<Scalars['Float']>,
  z?: Maybe<Scalars['Float']>,
};

export enum ViewableType {
  TwoDimensional = 'TWO_DIMENSIONAL',
  ThreeDimensional = 'THREE_DIMENSIONAL'
}

export type ViewerViewpoint = {
  camera?: Maybe<Vector>,
  /** 
 * Creation timestamp
   * 
   * Date and time when the entity was created
 */
  createdAt?: Maybe<Scalars['Date']>,
  /** 
 * Created by
   * 
   * String representation of the user, who created this entity
 */
  createdBy?: Maybe<Scalars['String']>,
  direction?: Maybe<Vector>,
  id?: Maybe<Scalars['Int']>,
  /** 
 * Last modification timestamp
   * 
   * Date and time when the entity was lastly modified
 */
  lastlyEditedAt?: Maybe<Scalars['Date']>,
  /** 
 * Last modification user
   * 
   * String representation of the user, who has done the last modification of this entity
 */
  lastlyEditedBy?: Maybe<Scalars['String']>,
  /** 
 * Modification history
   * 
   * Appendable user and timestamp, of the modification history
 */
  lastlyEditedHistory?: Maybe<Scalars['String']>,
  position?: Maybe<Vector>,
  project?: Maybe<Project>,
};

export type WhereExpressionGraph = {
  path?: Maybe<Scalars['String']>,
  comparison?: Maybe<ComparisonGraph>,
  negate?: Maybe<Scalars['Boolean']>,
  case?: Maybe<StringComparison>,
  value?: Maybe<Array<Maybe<Scalars['String']>>>,
  connector?: Maybe<Connector>,
  groupedExpressions?: Maybe<Array<Maybe<WhereExpressionGraph>>>,
};

export type CreateIssueMutationVariables = {
  projectId: Scalars['Int'],
  typeId: Scalars['Int'],
  statusId: Scalars['Int'],
  name: Scalars['String'],
  dueDate?: Maybe<Scalars['DateTime']>,
  causeId?: Maybe<Scalars['Int']>,
  locationDescription?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  projectFileVersionId?: Maybe<Scalars['Int']>,
  externalSystemCode: Scalars['String'],
  externalSystemLocation?: Maybe<Scalars['String']>
};


export type CreateIssueMutation = { createIssue: Maybe<{ name: Maybe<string>, id: Maybe<number>, assignedTo: Maybe<{ id: Maybe<number> }>, status: Maybe<{ name: Maybe<string> }>, type: Maybe<{ name: Maybe<string>, parent: Maybe<{ name: Maybe<string> }> }> }> };

export type GetFiltersQueryVariables = {
  projectFileVersionViewables?: Maybe<Array<Maybe<Scalars['Int']>>>,
  externalSystemCode: Scalars['String']
};


export type GetFiltersQuery = { filters: Maybe<Array<Maybe<{ name: Maybe<string>, code: string, items: Maybe<Array<Maybe<{ code: string, name: Maybe<string>, instancesPerFileVersionViewable: Maybe<Array<Maybe<{ instances: Maybe<Array<Maybe<string>>>, viewable: Maybe<{ id: Maybe<number> }> }>>> }>>> }>>> };

export type CompareVersionsQueryVariables = {
  projectFileVersionViewables?: Maybe<Array<Maybe<Scalars['Int']>>>,
  filters?: Maybe<Array<Maybe<FilterInput>>>,
  externalSystemCode: Scalars['String']
};


export type CompareVersionsQuery = { compareGroups: Maybe<{ comparedViewables: Maybe<Array<Maybe<{ projectFileVersion: Maybe<{ projectFile: Maybe<{ fileName: Maybe<string> }> }> }>>>, categories: Maybe<Array<Maybe<{ name: string, type: Maybe<CategoryType>, attributes: Maybe<Array<Maybe<(
        { children: Maybe<Array<Maybe<(
          { children: Maybe<Array<Maybe<(
            { children: Maybe<Array<Maybe<(
              { children: Maybe<Array<Maybe<ComparedValueFragment>>> }
              & ComparedValueFragment
            )>>> }
            & ComparedValueFragment
          )>>> }
          & ComparedValueFragment
        )>>> }
        & ComparedValueFragment
      )>>> }>>> }> };

export type ComparedValueFragment = { name: string, code: string, description: Maybe<string>, attributeType: Maybe<{ dataType: Maybe<DataType>, unit: Maybe<string> }>, comparedValues: Maybe<Array<Maybe<{ code: string, value: Maybe<string>, instances: Maybe<Array<Maybe<string>>> }>>> };

export type GetProjectsQueryVariables = {};


export type GetProjectsQuery = { projects: Maybe<Array<Maybe<{ name: Maybe<string>, code: Maybe<string>, dashboards: Maybe<Array<Maybe<{ dashboardType: Maybe<DashboardType>, attributes: Maybe<Array<Maybe<{ attribute: Maybe<{ code: Maybe<string>, name: Maybe<string>, value: Maybe<string>, attributeType: Maybe<{ unit: Maybe<string>, dataType: Maybe<DataType> }> }> }>>> }>>> }>>> };

export type GetProjectQueryVariables = {
  code?: Maybe<Scalars['String']>
};


export type GetProjectQuery = { projects: Maybe<Array<Maybe<{ id: Maybe<number>, name: Maybe<string>, code: Maybe<string>, dataModel: Maybe<{ id: Maybe<number> }>, projectInExternalSystems: Maybe<Array<Maybe<{ externalId: Maybe<string>, tempFolderId: Maybe<string>, externalSystem: Maybe<{ code: Maybe<string> }> }>>>, projectFiles: Maybe<Array<Maybe<{ id: Maybe<number>, name: Maybe<string>, fileName: Maybe<string>, fileType: Maybe<{ code: Maybe<string> }>, projectFileVersions: Maybe<Array<Maybe<{ id: Maybe<number>, version: Maybe<number>, fileVersionsInExternalSystem: Maybe<Array<Maybe<{ externalId: Maybe<string>, externalSystem: Maybe<{ code: Maybe<string> }> }>>>, viewables: Maybe<Array<Maybe<{ id: Maybe<number>, name: Maybe<string>, viewableType: Maybe<ViewableType>, viewableInExternalSystem: Maybe<Array<Maybe<{ externalId: Maybe<string>, externalSystem: Maybe<{ code: Maybe<string> }> }>>> }>>> }>>> }>>>, services: Maybe<Array<Maybe<{ allowed: Maybe<boolean>, sortOrder: Maybe<number>, service: Maybe<{ name: Maybe<string>, code: Maybe<string>, defaultOrder: Maybe<any>, color: Maybe<string>, presentationLink: Maybe<string> }> }>>> }>>> };

export type GetFormConfigQueryVariables = {
  projectCode?: Maybe<Scalars['String']>
};


export type GetFormConfigQuery = { types: Maybe<Array<Maybe<(
    { children: Maybe<Array<Maybe<TypeFieldsFragment>>> }
    & TypeFieldsFragment
  )>>>, causes: Maybe<Array<Maybe<(
    { children: Maybe<Array<Maybe<CauseFieldsFragment>>> }
    & CauseFieldsFragment
  )>>>, statuses: Maybe<Array<Maybe<{ id: Maybe<number>, name: Maybe<string>, description: Maybe<string>, highlighColor: Maybe<string> }>>>, users: Maybe<Array<Maybe<{ id: Maybe<number> }>>> };

export type TypeFieldsFragment = { id: Maybe<number>, name: Maybe<string>, description: Maybe<string> };

export type CauseFieldsFragment = { id: Maybe<number>, name: Maybe<string>, description: Maybe<string> };

export type GetIssuesForProjectFileVersionQueryVariables = {
  projectFileVersionId?: Maybe<Scalars['Int']>
};


export type GetIssuesForProjectFileVersionQuery = { issues: Maybe<Array<Maybe<{ id: Maybe<number>, name: Maybe<string>, code: Maybe<string>, description: Maybe<string>, dueDate: Maybe<any>, locationDescription: Maybe<string>, cause: Maybe<{ id: Maybe<number>, name: Maybe<string> }>, issuesInExternalSystems: Maybe<Array<Maybe<{ externalId: Maybe<string>, position: Maybe<string>, externalSystem: Maybe<{ code: Maybe<string> }> }>>>, status: Maybe<{ id: Maybe<number>, name: Maybe<string>, highlighColor: Maybe<string>, statusesInExternalSystems: Maybe<Array<Maybe<{ externalId: Maybe<string>, externalSystem: Maybe<{ code: Maybe<string> }> }>>> }>, type: Maybe<{ id: Maybe<number>, name: Maybe<string>, parent: Maybe<{ id: Maybe<number>, name: Maybe<string> }> }> }>>> };

export type GetIssuesIdsForProjectFileVersionQueryVariables = {
  projectFileVersionId?: Maybe<Scalars['String']>
};


export type GetIssuesIdsForProjectFileVersionQuery = { issues: Maybe<Array<Maybe<{ id: Maybe<number> }>>> };

export type GetViewableTypedIdsQueryVariables = {
  viewableID: Scalars['String']
};


export type GetViewableTypedIdsQuery = { rooms: Maybe<Array<Maybe<{ externalId: Maybe<string> }>>>, archs: Maybe<Array<Maybe<{ externalId: Maybe<string> }>>> };

export const ComparedValueFragmentDoc = gql`
    fragment ComparedValue on ComparedAttribute {
  name
  code
  description
  attributeType {
    dataType
    unit
  }
  comparedValues {
    code
    value
    instances
  }
}
    `;
export const TypeFieldsFragmentDoc = gql`
    fragment typeFields on IssueType {
  id
  name
  description
}
    `;
export const CauseFieldsFragmentDoc = gql`
    fragment causeFields on IssueCause {
  id
  name
  description
}
    `;
export const CreateIssueDocument = gql`
    mutation createIssue($projectId: Int!, $typeId: Int!, $statusId: Int!, $name: String!, $dueDate: DateTime, $causeId: Int, $locationDescription: String, $description: String, $projectFileVersionId: Int, $externalSystemCode: String!, $externalSystemLocation: String) {
  createIssue(issue: {projectId: $projectId, typeId: $typeId, statusId: $statusId, name: $name, dueDate: $dueDate, causeId: $causeId, locationDescription: $locationDescription, description: $description, projectFileVersionId: $projectFileVersionId, externalSystemData: {externalSystemCode: $externalSystemCode, location: $externalSystemLocation}}) {
    name
    id
    assignedTo {
      id
    }
    status {
      name
    }
    type {
      name
      parent {
        name
      }
    }
  }
}
    `;
export type CreateIssueMutationFn = ApolloReactCommon.MutationFunction<CreateIssueMutation, CreateIssueMutationVariables>;
export type CreateIssueComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateIssueMutation, CreateIssueMutationVariables>, 'mutation'>;

    export const CreateIssueComponent = (props: CreateIssueComponentProps) => (
      <ApolloReactComponents.Mutation<CreateIssueMutation, CreateIssueMutationVariables> mutation={CreateIssueDocument} {...props} />
    );
    

/**
 * __useCreateIssueMutation__
 *
 * To run a mutation, you first call `useCreateIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIssueMutation, { data, loading, error }] = useCreateIssueMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      typeId: // value for 'typeId'
 *      statusId: // value for 'statusId'
 *      name: // value for 'name'
 *      dueDate: // value for 'dueDate'
 *      causeId: // value for 'causeId'
 *      locationDescription: // value for 'locationDescription'
 *      description: // value for 'description'
 *      projectFileVersionId: // value for 'projectFileVersionId'
 *      externalSystemCode: // value for 'externalSystemCode'
 *      externalSystemLocation: // value for 'externalSystemLocation'
 *   },
 * });
 */
export function useCreateIssueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateIssueMutation, CreateIssueMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateIssueMutation, CreateIssueMutationVariables>(CreateIssueDocument, baseOptions);
      }
export type CreateIssueMutationHookResult = ReturnType<typeof useCreateIssueMutation>;
export type CreateIssueMutationResult = ApolloReactCommon.MutationResult<CreateIssueMutation>;
export type CreateIssueMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateIssueMutation, CreateIssueMutationVariables>;
export const GetFiltersDocument = gql`
    query getFilters($projectFileVersionViewables: [Int], $externalSystemCode: String!) {
  filters: compareFilters(projectFileVersionViewables: $projectFileVersionViewables, externalSystemCode: $externalSystemCode) {
    name
    code
    items {
      code
      name
      instancesPerFileVersionViewable {
        viewable {
          id
        }
        instances
      }
    }
  }
}
    `;
export type GetFiltersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetFiltersQuery, GetFiltersQueryVariables>, 'query'> & ({ variables: GetFiltersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetFiltersComponent = (props: GetFiltersComponentProps) => (
      <ApolloReactComponents.Query<GetFiltersQuery, GetFiltersQueryVariables> query={GetFiltersDocument} {...props} />
    );
    

/**
 * __useGetFiltersQuery__
 *
 * To run a query within a React component, call `useGetFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFiltersQuery({
 *   variables: {
 *      projectFileVersionViewables: // value for 'projectFileVersionViewables'
 *      externalSystemCode: // value for 'externalSystemCode'
 *   },
 * });
 */
export function useGetFiltersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFiltersQuery, GetFiltersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFiltersQuery, GetFiltersQueryVariables>(GetFiltersDocument, baseOptions);
      }
export function useGetFiltersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFiltersQuery, GetFiltersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFiltersQuery, GetFiltersQueryVariables>(GetFiltersDocument, baseOptions);
        }
export type GetFiltersQueryHookResult = ReturnType<typeof useGetFiltersQuery>;
export type GetFiltersLazyQueryHookResult = ReturnType<typeof useGetFiltersLazyQuery>;
export type GetFiltersQueryResult = ApolloReactCommon.QueryResult<GetFiltersQuery, GetFiltersQueryVariables>;
export const CompareVersionsDocument = gql`
    query compareVersions($projectFileVersionViewables: [Int], $filters: [FilterInput], $externalSystemCode: String!) {
  compareGroups: compareVersions(projectFileVersionViewables: $projectFileVersionViewables, externalSystemCode: $externalSystemCode, filters: $filters) {
    comparedViewables {
      projectFileVersion {
        projectFile {
          fileName
        }
      }
    }
    categories {
      name
      type
      attributes {
        ...ComparedValue
        children {
          ...ComparedValue
          children {
            ...ComparedValue
            children {
              ...ComparedValue
              children {
                ...ComparedValue
              }
            }
          }
        }
      }
    }
  }
}
    ${ComparedValueFragmentDoc}`;
export type CompareVersionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CompareVersionsQuery, CompareVersionsQueryVariables>, 'query'> & ({ variables: CompareVersionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CompareVersionsComponent = (props: CompareVersionsComponentProps) => (
      <ApolloReactComponents.Query<CompareVersionsQuery, CompareVersionsQueryVariables> query={CompareVersionsDocument} {...props} />
    );
    

/**
 * __useCompareVersionsQuery__
 *
 * To run a query within a React component, call `useCompareVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompareVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompareVersionsQuery({
 *   variables: {
 *      projectFileVersionViewables: // value for 'projectFileVersionViewables'
 *      filters: // value for 'filters'
 *      externalSystemCode: // value for 'externalSystemCode'
 *   },
 * });
 */
export function useCompareVersionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompareVersionsQuery, CompareVersionsQueryVariables>) {
        return ApolloReactHooks.useQuery<CompareVersionsQuery, CompareVersionsQueryVariables>(CompareVersionsDocument, baseOptions);
      }
export function useCompareVersionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompareVersionsQuery, CompareVersionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompareVersionsQuery, CompareVersionsQueryVariables>(CompareVersionsDocument, baseOptions);
        }
export type CompareVersionsQueryHookResult = ReturnType<typeof useCompareVersionsQuery>;
export type CompareVersionsLazyQueryHookResult = ReturnType<typeof useCompareVersionsLazyQuery>;
export type CompareVersionsQueryResult = ApolloReactCommon.QueryResult<CompareVersionsQuery, CompareVersionsQueryVariables>;
export const GetProjectsDocument = gql`
    query getProjects {
  projects: projects(orderBy: {path: "code"}) {
    name
    code
    dashboards(where: {path: "code", comparison: equal, value: "summary"}) {
      dashboardType
      attributes {
        attribute {
          code
          name
          value
          attributeType {
            unit
            dataType
          }
        }
      }
    }
  }
}
    `;
export type GetProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectsQuery, GetProjectsQueryVariables>, 'query'>;

    export const GetProjectsComponent = (props: GetProjectsComponentProps) => (
      <ApolloReactComponents.Query<GetProjectsQuery, GetProjectsQueryVariables> query={GetProjectsDocument} {...props} />
    );
    

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, baseOptions);
      }
export function useGetProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, baseOptions);
        }
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<typeof useGetProjectsLazyQuery>;
export type GetProjectsQueryResult = ApolloReactCommon.QueryResult<GetProjectsQuery, GetProjectsQueryVariables>;
export const GetProjectDocument = gql`
    query getProject($code: String) {
  projects: projects(where: {path: "code", comparison: equal, value: [$code]}) {
    id
    name
    code
    dataModel {
      id
    }
    projectInExternalSystems {
      externalId
      tempFolderId
      externalSystem {
        code
      }
    }
    projectFiles {
      id
      name
      fileName
      fileType {
        code
      }
      projectFileVersions(orderBy: {path: "version", descending: true}) {
        id
        version
        fileVersionsInExternalSystem {
          externalId
          externalSystem {
            code
          }
        }
        viewables(orderBy: {path: "name"}) {
          id
          name
          viewableType
          viewableInExternalSystem {
            externalId
            externalSystem {
              code
            }
          }
        }
      }
    }
    services(orderBy: [{path: "sortOrder"}, {path: "service.defaultOrder"}], where: {path: "service.type", comparison: equal, value: "PROJECT"}) {
      service {
        name
        code
        defaultOrder
        color
        presentationLink
      }
      allowed
      sortOrder
    }
  }
}
    `;
export type GetProjectComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectQuery, GetProjectQueryVariables>, 'query'>;

    export const GetProjectComponent = (props: GetProjectComponentProps) => (
      <ApolloReactComponents.Query<GetProjectQuery, GetProjectQueryVariables> query={GetProjectDocument} {...props} />
    );
    

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, baseOptions);
      }
export function useGetProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, baseOptions);
        }
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<typeof useGetProjectLazyQuery>;
export type GetProjectQueryResult = ApolloReactCommon.QueryResult<GetProjectQuery, GetProjectQueryVariables>;
export const GetFormConfigDocument = gql`
    query getFormConfig($projectCode: String) {
  types: issueTypes(where: {path: "project.code", comparison: equal, value: [$projectCode]}) {
    ...typeFields
    children {
      ...typeFields
    }
  }
  causes: issueCauses(where: {path: "project.code", comparison: equal, value: [$projectCode]}) {
    ...causeFields
    children {
      ...causeFields
    }
  }
  statuses: issueStatuses {
    id
    name
    description
    highlighColor
  }
  users: users {
    id
  }
}
    ${TypeFieldsFragmentDoc}
${CauseFieldsFragmentDoc}`;
export type GetFormConfigComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetFormConfigQuery, GetFormConfigQueryVariables>, 'query'>;

    export const GetFormConfigComponent = (props: GetFormConfigComponentProps) => (
      <ApolloReactComponents.Query<GetFormConfigQuery, GetFormConfigQueryVariables> query={GetFormConfigDocument} {...props} />
    );
    

/**
 * __useGetFormConfigQuery__
 *
 * To run a query within a React component, call `useGetFormConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormConfigQuery({
 *   variables: {
 *      projectCode: // value for 'projectCode'
 *   },
 * });
 */
export function useGetFormConfigQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFormConfigQuery, GetFormConfigQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFormConfigQuery, GetFormConfigQueryVariables>(GetFormConfigDocument, baseOptions);
      }
export function useGetFormConfigLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFormConfigQuery, GetFormConfigQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFormConfigQuery, GetFormConfigQueryVariables>(GetFormConfigDocument, baseOptions);
        }
export type GetFormConfigQueryHookResult = ReturnType<typeof useGetFormConfigQuery>;
export type GetFormConfigLazyQueryHookResult = ReturnType<typeof useGetFormConfigLazyQuery>;
export type GetFormConfigQueryResult = ApolloReactCommon.QueryResult<GetFormConfigQuery, GetFormConfigQueryVariables>;
export const GetIssuesForProjectFileVersionDocument = gql`
    query getIssuesForProjectFileVersion($projectFileVersionId: Int) {
  issues: syncedIssues(projectFileVersionId: $projectFileVersionId) {
    id
    name
    code
    cause {
      id
      name
    }
    description
    dueDate
    issuesInExternalSystems(where: {path: "externalSystem.code", comparison: equal, value: "forge"}) {
      externalId
      position
      externalSystem {
        code
      }
    }
    locationDescription
    status {
      id
      name
      highlighColor
      statusesInExternalSystems(where: {path: "externalSystem.code", comparison: equal, value: "forge"}) {
        externalId
        externalSystem {
          code
        }
      }
    }
    type {
      id
      name
      parent {
        id
        name
      }
    }
  }
}
    `;
export type GetIssuesForProjectFileVersionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetIssuesForProjectFileVersionQuery, GetIssuesForProjectFileVersionQueryVariables>, 'query'>;

    export const GetIssuesForProjectFileVersionComponent = (props: GetIssuesForProjectFileVersionComponentProps) => (
      <ApolloReactComponents.Query<GetIssuesForProjectFileVersionQuery, GetIssuesForProjectFileVersionQueryVariables> query={GetIssuesForProjectFileVersionDocument} {...props} />
    );
    

/**
 * __useGetIssuesForProjectFileVersionQuery__
 *
 * To run a query within a React component, call `useGetIssuesForProjectFileVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssuesForProjectFileVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssuesForProjectFileVersionQuery({
 *   variables: {
 *      projectFileVersionId: // value for 'projectFileVersionId'
 *   },
 * });
 */
export function useGetIssuesForProjectFileVersionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetIssuesForProjectFileVersionQuery, GetIssuesForProjectFileVersionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetIssuesForProjectFileVersionQuery, GetIssuesForProjectFileVersionQueryVariables>(GetIssuesForProjectFileVersionDocument, baseOptions);
      }
export function useGetIssuesForProjectFileVersionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetIssuesForProjectFileVersionQuery, GetIssuesForProjectFileVersionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetIssuesForProjectFileVersionQuery, GetIssuesForProjectFileVersionQueryVariables>(GetIssuesForProjectFileVersionDocument, baseOptions);
        }
export type GetIssuesForProjectFileVersionQueryHookResult = ReturnType<typeof useGetIssuesForProjectFileVersionQuery>;
export type GetIssuesForProjectFileVersionLazyQueryHookResult = ReturnType<typeof useGetIssuesForProjectFileVersionLazyQuery>;
export type GetIssuesForProjectFileVersionQueryResult = ApolloReactCommon.QueryResult<GetIssuesForProjectFileVersionQuery, GetIssuesForProjectFileVersionQueryVariables>;
export const GetIssuesIdsForProjectFileVersionDocument = gql`
    query GetIssuesIdsForProjectFileVersion($projectFileVersionId: String) {
  issues: issues(where: {path: "projectFileVersion.id", comparison: equal, value: [$projectFileVersionId]}) {
    id
  }
}
    `;
export type GetIssuesIdsForProjectFileVersionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetIssuesIdsForProjectFileVersionQuery, GetIssuesIdsForProjectFileVersionQueryVariables>, 'query'>;

    export const GetIssuesIdsForProjectFileVersionComponent = (props: GetIssuesIdsForProjectFileVersionComponentProps) => (
      <ApolloReactComponents.Query<GetIssuesIdsForProjectFileVersionQuery, GetIssuesIdsForProjectFileVersionQueryVariables> query={GetIssuesIdsForProjectFileVersionDocument} {...props} />
    );
    

/**
 * __useGetIssuesIdsForProjectFileVersionQuery__
 *
 * To run a query within a React component, call `useGetIssuesIdsForProjectFileVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssuesIdsForProjectFileVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssuesIdsForProjectFileVersionQuery({
 *   variables: {
 *      projectFileVersionId: // value for 'projectFileVersionId'
 *   },
 * });
 */
export function useGetIssuesIdsForProjectFileVersionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetIssuesIdsForProjectFileVersionQuery, GetIssuesIdsForProjectFileVersionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetIssuesIdsForProjectFileVersionQuery, GetIssuesIdsForProjectFileVersionQueryVariables>(GetIssuesIdsForProjectFileVersionDocument, baseOptions);
      }
export function useGetIssuesIdsForProjectFileVersionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetIssuesIdsForProjectFileVersionQuery, GetIssuesIdsForProjectFileVersionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetIssuesIdsForProjectFileVersionQuery, GetIssuesIdsForProjectFileVersionQueryVariables>(GetIssuesIdsForProjectFileVersionDocument, baseOptions);
        }
export type GetIssuesIdsForProjectFileVersionQueryHookResult = ReturnType<typeof useGetIssuesIdsForProjectFileVersionQuery>;
export type GetIssuesIdsForProjectFileVersionLazyQueryHookResult = ReturnType<typeof useGetIssuesIdsForProjectFileVersionLazyQuery>;
export type GetIssuesIdsForProjectFileVersionQueryResult = ApolloReactCommon.QueryResult<GetIssuesIdsForProjectFileVersionQuery, GetIssuesIdsForProjectFileVersionQueryVariables>;
export const GetViewableTypedIdsDocument = gql`
    query GetViewableTypedIds($viewableID: String!) {
  rooms: instanceViewerIds(where: [{path: "instance.element.code", comparison: equal, value: "raum"}, {path: "instance.projectFileVersionViewable.id", comparison: equal, value: [$viewableID]}]) {
    externalId
  }
  archs: instanceViewerIds(where: [{path: "instance.element.code", comparison: equal, value: "raum", negate: true}, {path: "instance.projectFileVersionViewable.id", comparison: equal, value: [$viewableID]}]) {
    externalId
  }
}
    `;
export type GetViewableTypedIdsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetViewableTypedIdsQuery, GetViewableTypedIdsQueryVariables>, 'query'> & ({ variables: GetViewableTypedIdsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetViewableTypedIdsComponent = (props: GetViewableTypedIdsComponentProps) => (
      <ApolloReactComponents.Query<GetViewableTypedIdsQuery, GetViewableTypedIdsQueryVariables> query={GetViewableTypedIdsDocument} {...props} />
    );
    

/**
 * __useGetViewableTypedIdsQuery__
 *
 * To run a query within a React component, call `useGetViewableTypedIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetViewableTypedIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetViewableTypedIdsQuery({
 *   variables: {
 *      viewableID: // value for 'viewableID'
 *   },
 * });
 */
export function useGetViewableTypedIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetViewableTypedIdsQuery, GetViewableTypedIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetViewableTypedIdsQuery, GetViewableTypedIdsQueryVariables>(GetViewableTypedIdsDocument, baseOptions);
      }
export function useGetViewableTypedIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetViewableTypedIdsQuery, GetViewableTypedIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetViewableTypedIdsQuery, GetViewableTypedIdsQueryVariables>(GetViewableTypedIdsDocument, baseOptions);
        }
export type GetViewableTypedIdsQueryHookResult = ReturnType<typeof useGetViewableTypedIdsQuery>;
export type GetViewableTypedIdsLazyQueryHookResult = ReturnType<typeof useGetViewableTypedIdsLazyQuery>;
export type GetViewableTypedIdsQueryResult = ApolloReactCommon.QueryResult<GetViewableTypedIdsQuery, GetViewableTypedIdsQueryVariables>;
import { createSelector } from "reselect";
import { IDashboardModuleState, IDashboardState } from "./reducer";

export function selectDashboardState(state: IDashboardModuleState): IDashboardState {
    return state.dashboardState;
}
export const selectModelById = (modelId: number) =>
    createSelector(selectDashboardState, state => state.models.find(model => model.id === modelId));

export const selectPanel = createSelector(selectDashboardState, state => state.panel);
export const selectRepresent = createSelector(selectDashboardState, state => state.panel.represent);
export const selectSurrounding = createSelector(selectDashboardState, state => state.panel.surrounding);
export const selectIsolate = createSelector(selectDashboardState, state => state.panel.isolate);
export const selectSyncView = createSelector(selectDashboardState, state => state.panel.syncView);
export const selectOverlap = createSelector(selectDashboardState, state => state.panel.overlap);
export const selectTurntable = createSelector(selectDashboardState, state => state.panel.turntable);
export const selectSpeed = createSelector(selectDashboardState, state => state.panel.speed);

export const selectFilters = createSelector(selectDashboardState, state => state.filters);
export const selectModels = createSelector(selectDashboardState, state => state.models);
export const selectModelVariantIds = createSelector(selectDashboardState, state =>
    state.models.map(m => m.variantId as number).filter(m => m != null),
);
export const selectIssuables = createSelector(selectDashboardState, state => state.issuables);
export const selectIssues = createSelector(selectDashboardState, state => state.issues);

import { DispatchAction } from "@iolabs/redux-utils";
import { onError } from "@iolabs/app";
import {
    Box,
    Checkbox,
    Chip,
    createStyles,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Paper,
    TextField,
    Theme,
    Typography,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useGetFiltersQuery } from "../../graphql/generated/graphql";
import { onFilterChange, useModelVariantIds } from "../../redux/dashboard";
import globalMessages from "../App/messages";
import messages from "./messages";
import { ExternalSystem } from "../Viewer/types/type";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1),
            height: "100%",
        },
        title: {
            textTransform: "uppercase",
            marginLeft: theme.spacing(1),
            marginBottom: theme.spacing(0.5),
        },
        paper: {
            borderColor: theme.palette.grey[600],
            padding: theme.spacing(1),
            height: "calc(100% - 25px)",
        },
        fieldset: {
            width: "100%",
        },
        legend: {
            color: theme.palette.grey[600],
            fontSize: ".9rem",
            "&.Mui-focused": {
                color: theme.palette.grey[600],
            },
        },
        checkbox: {
            padding: "4px",
            marginLeft: "5px",
        },
        formGroupCheckboxes: {
            display: "flex",
            flexFlow: "row wrap",
            padding: theme.spacing(1),
            "& .MuiFormControlLabel-label": {
                minWidth: "2.2rem",
            },
        },
        formGroupAutocomplete: {
            marginTop: theme.spacing(1),
            marginLeft: "4px",
            marginRight: "4px",
            marginBottom: "4px",
            "& .MuiChip-root": {
                borderRadius: "4px",
            },
        },
        skeletonBox: {
            "& span": {
                width: "100%",
                marginBottom: theme.spacing(1),
                borderRadius: theme.shape.borderRadius,
            },
        },
    }),
);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IFilterProps {}

const Filter: React.FC<IFilterProps> = () => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();

    // translations
    const intl = useIntl();
    const transFilterTitle = intl.formatMessage({ ...messages.filterTitle });
    const transLoadingDataError = intl.formatMessage({ ...globalMessages.loadingDataError });

    const variantIds = useModelVariantIds();
    const [filterValues, setFilterValues] = useState<{ [key: number]: string[] }>({});

    const { data, loading, error } = useGetFiltersQuery({
        skip: variantIds.length < 2,
        variables: {
            projectFileVersionViewables: variantIds,
            externalSystemCode: ExternalSystem.Forge,
        },
    });

    if (error) {
        dispatch(
            onError({
                errorMessage: transLoadingDataError,
            }),
        );
    }

    const filters = data?.filters;

    // useEffect(() => {
    //     let defaultFilter: any = [];
    //
    //     filters?.map(filter => {
    //         let obj = {
    //             code: filter?.code,
    //             items: [],
    //         };
    //         defaultFilter.push(obj);
    //     });
    //     // dispatch(onFilterDefault({ defaultFilter }));
    // }, [filters]);

    useEffect(() => {
        const filtersOut = {};

        Object.keys(filterValues).forEach(fvk => {
            // iterate over filters
            const fileVersionsViewables = {};
            filters
                ?.find(f => f?.code === fvk)
                ?.items?.filter(i => filterValues[fvk].includes(i?.code))
                .forEach(filterItem => {
                    // iterate over filter items
                    filterItem?.instancesPerFileVersionViewable?.forEach(ipfv => {
                        // iterate over instances

                        const versionViewableId = ipfv?.viewable?.id as number;
                        if (!fileVersionsViewables[versionViewableId]) {
                            fileVersionsViewables[versionViewableId] = [];
                        }
                        // @ts-ignore
                        fileVersionsViewables[versionViewableId] = fileVersionsViewables[versionViewableId].concat(
                            ipfv?.instances,
                        );
                    });
                });
            Object.keys(fileVersionsViewables).forEach(vId => {
                if (!filtersOut[vId]) {
                    filtersOut[vId] = {};
                }
                if (!filtersOut[vId][fvk]) {
                    filtersOut[vId][fvk] = [];
                }
                filtersOut[vId][fvk] = filtersOut[vId][fvk].concat(fileVersionsViewables[vId]);
            });
        });

        const filtersPerVersion = {};
        // do intersection
        Object.keys(filtersOut).forEach(vId => {
            Object.values(filtersOut[vId]).forEach(ids => {
                if (!filtersPerVersion[vId]) {
                    filtersPerVersion[vId] = ids;
                } else {
                    filtersPerVersion[vId] = filtersPerVersion[vId].filter(val => (ids as string[]).includes(val));
                }
            });
        });

        dispatch(onFilterChange({ filters: filterValues, visibleIds: filtersPerVersion }));
    }, [filterValues]);

    const handleChangeCheckbox = (filterCode: string, itemCode: string, checked: boolean) => {
        const actualFilter = filterValues[filterCode];
        let l: string[] = [];
        if (actualFilter) {
            l = l.concat(actualFilter);
        }
        if (checked) {
            l.push(itemCode);
        } else {
            l = l.filter(x => x !== itemCode);
        }
        setFilterValues({ ...filterValues, [filterCode]: l });
        // dispatch(onFilterChange({ filterCode, itemCode }));
    };

    const handleChangeAutocomplete = (event, filterCode: string, values) => {
        setFilterValues({ ...filterValues, [filterCode]: values.map(v => v.code) });
        // dispatch(onFilterChange({ filterCode, itemCode: values }));
    };

    return (
        <Box className={classes.root}>
            <Typography variant="inherit" component="p" className={classes.title}>
                {transFilterTitle}
            </Typography>
            <Paper variant="outlined" elevation={0} className={classes.paper}>
                <Box>
                    {(loading || error) && (
                        <Box className={classes.skeletonBox}>
                            <Skeleton variant="rect" height={50} />
                            <Skeleton variant="rect" height={50} />
                        </Box>
                    )}

                    {filters &&
                        filters?.map((filter, index) => {
                            return filter?.code === "geschoss" ? (
                                <FormControl key={index} component="fieldset" className={classes.fieldset}>
                                    <FormLabel component="legend" className={classes.legend}>
                                        {filter?.name}
                                    </FormLabel>
                                    <FormGroup className={classes.formGroupCheckboxes}>
                                        {filter?.items?.map((item, index) => {
                                            return (
                                                <FormControlLabel
                                                    key={index}
                                                    control={
                                                        <Checkbox
                                                            size="small"
                                                            color="primary"
                                                            name={item?.code}
                                                            onChange={(event: any) =>
                                                                handleChangeCheckbox(
                                                                    filter?.code,
                                                                    item!.code,
                                                                    event.target.checked,
                                                                )
                                                            }
                                                            className={classes.checkbox}
                                                        />
                                                    }
                                                    label={item?.name}
                                                />
                                            );
                                        })}
                                    </FormGroup>
                                </FormControl>
                            ) : (
                                <FormControl key={index} component="fieldset" className={classes.fieldset}>
                                    <FormLabel component="legend" className={classes.legend}>
                                        {filter?.name}
                                    </FormLabel>
                                    <FormGroup className={classes.formGroupAutocomplete}>
                                        {filter!.items && (
                                            <Autocomplete
                                                multiple
                                                size="small"
                                                id="autocomplete"
                                                options={filter!.items}
                                                getOptionLabel={option => option?.name as string}
                                                // getOptionDisabled={option => option?.code === 0}
                                                // defaultValue={[filter!.items[0], filter!.items[1]]}
                                                onChange={(event, values) =>
                                                    handleChangeAutocomplete(event, filter!.code, values)
                                                }
                                                renderOption={(option, { selected }) => (
                                                    <React.Fragment>
                                                        <Checkbox
                                                            size="small"
                                                            color="primary"
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            checked={selected}
                                                            className={classes.checkbox}
                                                        />
                                                        {option?.name}
                                                    </React.Fragment>
                                                )}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip
                                                            size="small"
                                                            variant="outlined"
                                                            label={option!.name}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                                renderInput={params => <TextField {...params} variant="outlined" />}
                                            />
                                        )}
                                    </FormGroup>
                                </FormControl>
                            );
                        })}
                </Box>
            </Paper>
        </Box>
    );
};

export default Filter;

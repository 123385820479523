import { onError, onPageTitle } from "@iolabs/app";
import { DispatchAction } from "@iolabs/redux-utils";
import { Box, createStyles, Grid, Paper, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import globalMessages from "../../components/App/messages";
import Charts from "../../components/Dashboard/Charts";
import DataTable from "../../components/Dashboard/DataTable";
import Filter from "../../components/Dashboard/Filter";
import Info from "../../components/Dashboard/Info";
import ProjectModelViewer from "../../components/VersionSelector/ProjectModelViewer";
import { activateSyncViewNavigation } from "../../components/Viewer/extensions/Viewing.Extensions.SyncViewNavigation/service";
import { ProjectFile, ProjectFileVersionViewable, useGetProjectQuery } from "../../graphql/generated/graphql";
import { onViewableSelect, useModels, usePanelSyncView } from "../../redux/dashboard";
import DashboardPanel from "./DashboardPanel";
import messages from "./messages";

const controlPanelHeight = 45;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerContainer: {
            minHeight: "25vh",
            alignItems: "stretch",
        },
        projectContainer: {},
        textGrid: {
            padding: theme.spacing(2),
            "& p": {
                fontSize: "12px",
            },
        },
        projectsGrid: {
            padding: theme.spacing(2),
            paddingTop: 0,
            marginBottom: "4rem",
            [theme.breakpoints.up("md")]: {
                marginBottom: "1rem",
            },
        },
        title: {
            color: theme.palette.border.dark,
        },
        subtitle: {
            marginTop: theme.spacing(1),
            fontSize: "14px",
        },
        itemPrimary: {
            height: `${controlPanelHeight}px`,
            minHeight: `${controlPanelHeight}px`,
            background: theme.palette.border[500],
        },
        root: {
            height: "100%",
            alignContent: "flex-start",
        },
        itemSecondary: {
            [theme.breakpoints.up("lg")]: {
                order: 2,
                height: `calc(100% - ${controlPanelHeight}px)`,
            },
        },
        itemTernary: {
            padding: theme.spacing(1),
        },
        itemTernaryContainer: {
            border: `1px solid ${theme.palette.grey[600]}`,
            borderRadius: theme.shape.borderRadius,
            marginBottom: "4rem",
            minHeight: "20vh",
            height: "auto",
            [theme.breakpoints.up("md")]: {
                marginBottom: "2rem",
                height: "100%",
            },
            [theme.breakpoints.up("lg")]: {
                marginBottom: 0,
                minHeight: "100%",
                height: "100%",
            },
        },
        itemSecondaryContainer: {
            height: "auto",
            [theme.breakpoints.up("md")]: {
                minHeight: "50%",
            },
            [theme.breakpoints.up("lg")]: {
                height: "50%",
            },
        },
        itemViewer1: {
            minHeight: "30vh",
            [theme.breakpoints.up("lg")]: {
                minHeight: "auto",
            },
        },
        itemViewer2: {
            minHeight: "30vh",
            [theme.breakpoints.up("lg")]: {
                minHeight: "auto",
            },
        },
        itemDataTable: {
            maxHeight: "100%",
        },
        itemFilter: {},
        itemInfo: {
            minHeight: "50%",
            [theme.breakpoints.up("md")]: {
                height: "50%",
            },
        },
        itemCharts: {
            minHeight: "50%",
            [theme.breakpoints.up("md")]: {
                height: "50%",
            },
        },
        viewerBox: {
            padding: theme.spacing(1),
            paddingBottom: 0,
            height: "100%",
        },
        paper: {
            position: "relative",
            borderWidth: theme.spacing(0.8),
            height: "100%",
        },
        paper1: {
            borderColor: theme.palette.border[200],
        },
        paper2: {
            borderColor: theme.palette.border[100],
        },
    }),
);

const DashboardPage: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();
    const modelsState = useModels();
    const syncView = usePanelSyncView();

    // translations
    const intl = useIntl();
    const transPageTitle = intl.formatMessage({ ...messages.pageTitle });

    const transLoadingDataError = intl.formatMessage({ ...globalMessages.loadingDataError });
    const { data, loading, error } = useGetProjectQuery({
        variables: {
            code: "01", // todo how to get project code?
        },
    });

    if (error) {
        dispatch(
            onError({
                errorMessage: transLoadingDataError,
            }),
        );
    }

    const models: ProjectFile[] = data?.projects
        ?.find(pr => pr?.code === "01")
        ?.projectFiles?.filter(pf => pf?.fileType?.code === "GeometryFile") as ProjectFile[];

    useEffect(() => {
        dispatch(onPageTitle({ pageTitle: transPageTitle }));
    }, [transPageTitle]);

    useEffect(() => {
        activateSyncViewNavigation(syncView);
    }, [syncView]);

    const onViewableSelected = (modelIndex: number, viewable: ProjectFileVersionViewable) => {
        dispatch(
            onViewableSelect({
                index: modelIndex,
                viewable: viewable,
            }),
        );
    };

    return (
        <Switch>
            <Route
                render={() => (
                    <Grid container spacing={0} className={classes.root}>
                        <Grid item xs={12} sm={12} className={classes.itemPrimary}>
                            <DashboardPanel />
                        </Grid>

                        <Grid item xs={12} sm={12} lg={9} className={classes.itemSecondary}>
                            <Grid container spacing={0} className={classes.itemSecondaryContainer}>
                                <Grid item xs={6} sm={6} className={classes.itemViewer1}>
                                    <Box className={classes.viewerBox}>
                                        <Paper
                                            variant="outlined"
                                            elevation={0}
                                            className={clsx(classes.paper, classes.paper1)}
                                        >
                                            <ProjectModelViewer
                                                modelId={models && (models[2]?.id as number)}
                                                count={modelsState[0]?.issues.length as number}
                                                selectionIds={modelsState[0]?.selectedIds}
                                                showOnlyIds={modelsState[0]?.showOnlyIds}
                                                onViewableSelected={viewable => onViewableSelected(0, viewable)}
                                            />
                                        </Paper>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={6} className={classes.itemViewer2}>
                                    <Box className={classes.viewerBox}>
                                        <Paper
                                            variant="outlined"
                                            elevation={0}
                                            className={clsx(classes.paper, classes.paper2)}
                                        >
                                            <ProjectModelViewer
                                                modelId={models && (models[1]?.id as number)}
                                                count={modelsState[1]?.issues.length as number}
                                                selectionIds={modelsState[1]?.selectedIds}
                                                showOnlyIds={modelsState[1]?.showOnlyIds}
                                                onViewableSelected={viewable => onViewableSelected(1, viewable)}
                                            />
                                        </Paper>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={0} className={classes.itemSecondaryContainer}>
                                <Grid item xs={12} sm={12} lg={9} className={classes.itemDataTable}>
                                    <DataTable />
                                </Grid>
                                <Grid item xs={12} sm={12} lg={3} className={classes.itemFilter}>
                                    <Filter />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} lg={3} className={classes.itemTernary}>
                            <Grid container spacing={0} className={classes.itemTernaryContainer}>
                                <Grid item xs={12} sm={12} className={classes.itemInfo}>
                                    <Info />
                                </Grid>
                                <Grid item xs={12} sm={12} className={classes.itemCharts}>
                                    <Charts />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            />
        </Switch>
    );
};

export default DashboardPage;

export const slugify = (text: string): string => {
    return text
        .toString()
        .toLowerCase()
        .trim()
        .replace(/\s+/g, "-") // replace spaces with -
        .replace(/&/g, "-and-") // replace & with 'and'
        .replace(/[^\w\-]+/g, "") // remove all non-word chars
        .replace(/--+/g, "-"); // replace multiple - with single -
};

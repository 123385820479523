import deepmerge from "deepmerge";
import { Vector3 } from "three";
import { registerViewer, unRegisterViewer } from "./service";

export const ExtensionID: string = "Viewing.Extension.SyncViewNavigation";

export interface ISyncViewNavigationExtensionOptions {}

const DefaultOptions: ISyncViewNavigationExtensionOptions = {};

export interface ICameraPosition {
    position: Vector3;
    target: Vector3;
    up: Vector3;
}

export const register = () => {
    class SyncViewNavigationExtension extends Autodesk.Viewing.Extension {
        private _group?: Autodesk.Viewing.UI.ControlGroup;
        private _button?: Autodesk.Viewing.UI.Button;
        private _started?: boolean;

        constructor(viewer: Autodesk.Viewing.GuiViewer3D, options?: ISyncViewNavigationExtensionOptions) {
            // @ts-ignore
            const opts = deepmerge(DefaultOptions, options);
            super(viewer, opts);
            this.viewer = viewer;
            this._started = false;
        }

        load() {
            registerViewer(this.viewer);
            return true;
        }
        unload() {
            unRegisterViewer(this.viewer);
            return true;
        }

        getNavigation(): ICameraPosition {
            const nav = this.viewer.navigation;
            return {
                position: nav.getPosition(),
                target: nav.getTarget(),
                up: nav.getCameraUpVector(),
            };
        }

        setNavigation(cameraPosition: ICameraPosition) {
            const nav = this.viewer.navigation;
            nav.setView(cameraPosition.position, cameraPosition.target);
            nav.setCameraUpVector(cameraPosition.up);
        }
    }
    // register extension - we need to do it here so extension is loaded by viewer
    Autodesk.Viewing.theExtensionManager.registerExtension(ExtensionID, SyncViewNavigationExtension);
};

import { DispatchAction } from "@iolabs/redux-utils";
import { useGlobalDialog } from "@iolabs/wip-dialog";
import { Box, createStyles, FormControlLabel, FormGroup, Switch, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useDispatch } from "react-redux";
import MessageButton from "../../components/ControlPanel/MessageButton";
import PdfButton from "../../components/ControlPanel/PdfButton";
import { onPanelSwitch, usePanel } from "../../redux/dashboard";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            overflowX: "auto",
            height: "100%",
            padding: theme.spacing(0.5),
        },
        box: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexFlow: "row nowrap",
            whiteSpace: "nowrap",
            justifyContent: "space-between",
            alignItems: "center",
        },
        formGroup: {
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "center",
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            "& label": {
                marginLeft: theme.spacing(0.5),
                marginRight: theme.spacing(1),
                cursor: "pointer",
                "& span": {
                    fontSize: ".9rem",
                },
            },
        },
        input: {
            width: "2.4rem",
            marginLeft: theme.spacing(1),
        },
    }),
);

interface IDashboardPanelProps {}

const DashboardPanel: React.FC<IDashboardPanelProps> = () => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();
    const panel = usePanel();
    const { setGlobalDialogOpen } = useGlobalDialog();
    const toggleRepresent = () => {
        dispatch(
            onPanelSwitch({
                represent: !panel.represent,
            }),
        );
    };

    const toggleSurrounding = () => {
        dispatch(
            onPanelSwitch({
                surrounding: !panel.surrounding,
            }),
        );
    };

    const toggleIsolate = () => {
        dispatch(
            onPanelSwitch({
                isolate: !panel.isolate,
            }),
        );
    };

    const toggleSyncView = () => {
        dispatch(
            onPanelSwitch({
                syncView: !panel.syncView,
            }),
        );
    };

    const toggleOverlap = () => {
        setGlobalDialogOpen(true);
        // dispatch(
        //     onPanelSwitch({
        //         overlap: !panel.overlap,
        //     }),
        // );
    };

    const toggleTurntable = () => {
        dispatch(
            onPanelSwitch({
                turntable: !panel.turntable,
            }),
        );
    };

    const handleSpeed = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
            onPanelSwitch({
                speed: parseInt(event.target.value) ? parseInt(event.target.value) : 0,
            }),
        );
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.box}>
                <FormGroup className={classes.formGroup}>
                    <FormControlLabel
                        control={
                            <Switch color="primary" size="small" checked={panel.represent} onChange={toggleRepresent} />
                        }
                        label="Soll Werte darstellen"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                color="primary"
                                size="small"
                                checked={panel.surrounding}
                                onChange={toggleSurrounding}
                            />
                        }
                        label="Umgebung"
                    />
                    <FormControlLabel
                        control={
                            <Switch color="primary" size="small" checked={panel.isolate} onChange={toggleIsolate} />
                        }
                        label="Isolate selection"
                    />
                    <FormControlLabel
                        control={
                            <Switch color="primary" size="small" checked={panel.syncView} onChange={toggleSyncView} />
                        }
                        label="Sync view"
                    />
                    {/*
                    <FormControlLabel
                        control={
                            <Switch color="primary" size="small" checked={panel.overlap} onChange={toggleOverlap} />
                        }
                        label="Modelle überlappen"
                    />
                    <FormControlLabel
                        control={
                            <Switch color="primary" size="small" checked={panel.turntable} onChange={toggleTurntable} />
                        }
                        label="Turntable"
                    />
                    <FormControlLabel
                        disabled={!panel.turntable}
                        labelPlacement="start"
                        control={
                            <Input
                                type="number"
                                value={panel.speed}
                                inputProps={{
                                    "aria-label": "speed",
                                    min: 0,
                                    max: 100,
                                }}
                                onChange={handleSpeed}
                                className={classes.input}
                            />
                        }
                        label="Geschwinddigkeit:"
                    />
                    */}
                </FormGroup>

                <div>
                    <PdfButton />
                    <MessageButton />
                </div>
            </Box>
        </Box>
    );
};

export default DashboardPanel;

import { AnyAction } from "redux";
import { IIssueState } from "../../components/Issues/type";
import { ActionTypes } from "./action";
import { CreateIssueMutationVariables, Issue } from "../../graphql/generated/graphql";

export interface IDashboardModuleState {
    dashboardState: IDashboardState;
}

export type IIssuable = {
    id: string;
    name?: string;
    collectIssueData: (onUpdated: (partialData: Partial<CreateIssueMutationVariables>) => void) => void;
    stopCollecting?: () => void;
    renderIssues: (issues: Issue[]) => void;
    disabled?: () => boolean;
};

export interface IDashboardState {
    models: Model[];
    panel: Panel;
    filters: Filter[];
    issuables: IIssuable[];
    issues: IIssueState[];
}

export type Model = {
    id: number;
    versionId?: number;
    variantId?: number;
    issues: IIssueState[];
    selectedIds: string[];
    showOnlyIds?: string[];
};

export type Panel = {
    represent: boolean; // Soll Werte darstellen
    surrounding: boolean; // Umgebung
    isolate: boolean; // Isolate selection
    syncView: boolean; // Sync view
    overlap: boolean; // Modelle überlappen
    turntable: boolean; // Turntable
    speed: number; // Geschwindigkeit
};

export type Filter = {
    code: string;
    items: FilterItem[] | [];
};

export type FilterItem = {
    code: string;
};

export const initialState: Readonly<IDashboardState> = {
    issuables: [],
    issues: [],
    models: [
        {
            id: 49,
            // versionId: 130,
            // variantId: 51,
            issues: [
                {
                    type: 1,
                    status: 0,
                    title: "Test",
                    assignTo: 1,
                    description: "Test",
                    dueDate: new Date("2020-05-13T17:01:00.000Z"),
                    location: "Test",
                    locationDetails: "Test",
                    owner: 0,
                    rootCause: 15,
                },
            ],
            selectedIds: [],
        },
        {
            id: 51,
            // versionId: 133,
            // variantId: 51,
            issues: [
                {
                    type: 0,
                    status: 1,
                    title: "Test",
                    assignTo: 2,
                    description: "Test",
                    dueDate: new Date("2020-05-13T17:01:00.000Z"),
                    location: "Test",
                    locationDetails: "Test",
                    owner: 0,
                    rootCause: 10,
                },
                {
                    type: 1,
                    status: 0,
                    title: "Test 2",
                    assignTo: 2,
                    description: "Test 2",
                    dueDate: new Date("2020-05-13T17:01:00.000Z"),
                    location: "Test 2",
                    locationDetails: "Test 2",
                    owner: 0,
                    rootCause: 1,
                },
                {
                    type: 1,
                    status: 0,
                    title: "Test 3",
                    assignTo: null,
                    description: "Test 3",
                    dueDate: null,
                    location: "Test 3",
                    locationDetails: "Test 3",
                    owner: 0,
                    rootCause: 1,
                },
            ],
            selectedIds: [],
        },
    ],
    panel: {
        represent: true,
        surrounding: true,
        isolate: false,
        syncView: false,
        overlap: false,
        turntable: false,
        speed: 50,
    },
    filters: [],
};

export function reducer(state: IDashboardState = initialState, action: AnyAction): IDashboardState {
    switch (action.type) {
        case ActionTypes.OnIssueCreate: {
            const { id, issue } = action.payload;
            return {
                ...state,
                models: state.models.map(model =>
                    model.id === id ? { ...model, issues: [...model.issues, issue] } : model,
                ),
            };
        }

        case ActionTypes.OnPanelSwitch: {
            const { represent, surrounding, isolate, syncView, overlap, turntable, speed } = action.payload;
            return {
                ...state,
                panel: {
                    represent: represent != null ? represent : state.panel.represent,
                    surrounding: surrounding != null ? surrounding : state.panel.surrounding,
                    isolate: isolate != null ? isolate : state.panel.isolate,
                    syncView: syncView != null ? syncView : state.panel.syncView,
                    overlap: overlap != null ? overlap : state.panel.overlap,
                    turntable: turntable != null ? turntable : state.panel.turntable,
                    speed: speed != null ? speed : state.panel.speed,
                },
            };
        }

        case ActionTypes.OnFilterDefault: {
            const { defaultFilter } = action.payload;
            return {
                ...state,
                filters: defaultFilter,
            };
        }

        case ActionTypes.OnFilterChange: {
            const { filters, visibleIds } = action.payload;

            let stateFilters: Filter[] = [];
            if (filters) {
                Object.keys(filters).forEach(filterCode => {
                    let filter = stateFilters.find(f => f.code === filterCode);
                    if (!filter) {
                        filter = { code: filterCode, items: [] };
                    }

                    filter.items = filters[filterCode].map(val => {
                        return { code: val };
                    });

                    // showOnlyIds[]
                    stateFilters.push(filter);
                });
            }
            return {
                ...state,
                filters: stateFilters,
                models: state.models.map(model => {
                    return {
                        ...model,
                        showOnlyIds:
                            model.variantId && visibleIds[model.variantId] ? visibleIds[model.variantId] : undefined,
                    };
                }),
            };
        }
        case ActionTypes.OnCompareItemSelected: {
            const { selectedIds } = action.payload;
            return {
                ...state,
                models: state.models.map(model => {
                    return { ...model, selectedIds: model.variantId ? selectedIds[model.variantId] : [] };
                }),
            };
        }
        case ActionTypes.OnViewableSelected: {
            const { index, viewable } = action.payload;
            return {
                ...state,
                models: state.models.map((model, i) => {
                    if (i === index) {
                        return { ...model, variantId: viewable.id };
                    } else {
                        return { ...model };
                    }
                }),
            };
        }

        case ActionTypes.OnRegisterIssuable: {
            const { issuable } = action.payload;
            return { ...state, issuables: [...state.issuables, issuable] };
        }

        case ActionTypes.OnUnregisterIssuable: {
            const { id } = action.payload;
            return { ...state, issuables: [...state.issuables.filter((item: IIssuable) => item.id !== id)] };
        }

        case ActionTypes.OnCreateIssue: {
            const { issue } = action.payload;
            return { ...state, issues: [...state.issues, issue] };
        }

        default:
            return state;
    }
}

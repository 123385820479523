import { IModule } from "redux-dynamic-modules";
import middlewares from "./middleware";
import { IDashboardModuleState, reducer } from "./reducer";

export const DashboardModule: IModule<IDashboardModuleState> = {
    id: "dashboard-module",
    reducerMap: {
        dashboardState: reducer,
    },
    middlewares: middlewares,
};

import React, { ReactNode } from "react";
import { Provider } from "react-redux";
import config from "../config/config";

import { createStore, IModuleStore } from "redux-dynamic-modules";
import { getThunkExtension } from "redux-dynamic-modules-thunk";
import { compose } from "redux";
import { AppModule } from "@iolabs/app";
import { LayoutModule } from "@iolabs/layout";
import { NotifierModule } from "@iolabs/notifier";
import { KeycloakModule } from "@iolabs/keycloak";
import { DashboardModule } from "./dashboard/module";

// add Redux debugging tool as Chrome extension when development environment is active
const composeEnhancers =
    config.envType === "development" ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

export interface IState {
    // appModule: IAppModuleState;
    // layoutModule: ILayoutModuleState;
    // notifierModule: INotifierModuleState;
    // keycloakModule: IKeycloakModuleState;
}

const store: IModuleStore<any> = createStore(
    {
        initialState: {
            // appModule: {
            //     appState: {}
            // },
            // layoutModule: {
            //     layoutState: {
            //         drawer: true,
            //         splitView: true
            //     }
            // },
            // notifierModule: {
            //     notifierState: notifierInitialState
            // },
            // keycloakModule: {
            //     keycloakState: keycloakInitialState
            // }
        },
        advancedComposeEnhancers: composeEnhancers,
        extensions: [getThunkExtension()],
    },
    AppModule,
    LayoutModule,
    NotifierModule,
    KeycloakModule,
    DashboardModule,
);

type Props = {
    children: ReactNode;
};

export function StoreProvider(props: Props): JSX.Element {
    const { children } = props;
    return <Provider store={store}>{children}</Provider>;
}

import { onPageTitle } from "@iolabs/app";
import { DispatchAction } from "@iolabs/redux-utils";
import { Button, Container, createStyles, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import messages from "./messages";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "center",
            justifyContent: "center",
            padding: theme.spacing(2),
        },
        button: {
            margin: theme.spacing(2),
        },
    }),
);

const ErrorPage: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();

    // translations
    const intl = useIntl();
    const transPageTitle = intl.formatMessage({ ...messages.pageTitle });
    const transBack = intl.formatMessage({ ...messages.back });

    useEffect(() => {
        dispatch(onPageTitle({ pageTitle: transPageTitle }));
    }, [dispatch, transPageTitle]);

    return (
        <Container className={classes.root}>
            <Typography component="h1" variant="h4">
                {transPageTitle}
            </Typography>

            <Button variant="contained" color="primary" component={RouterLink} to="/" className={classes.button}>
                {transBack}
            </Button>
        </Container>
    );
};

export default ErrorPage;

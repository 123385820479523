import { defineMessages } from "react-intl";

export default defineMessages({
    pageTitle: {
        id: "pages__issue-page__page-title",
        defaultMessage: "Issues",
    },
    redirectButton: {
        id: "pages__issue-page__redirect-button",
        defaultMessage: "Back to dashboard",
    },
});

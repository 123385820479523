import { onError } from "@iolabs/app";
import { DispatchAction } from "@iolabs/redux-utils";
import { Box, createStyles, Paper, Theme, Typography, useTheme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { ResponsiveBar } from "@nivo/bar";
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { ComparedAttribute, ComparerValue, useCompareVersionsQuery } from "../../graphql/generated/graphql";
import { useFilters, useModelVariantIds, usePanelRepresent } from "../../redux/dashboard";
import globalMessages from "../App/messages";
import { ExternalSystem } from "../Viewer/types/type";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1),
            height: "100%",
        },
        paper: {
            border: "none",
            display: "flex",
            flexFlow: "column nowrap",
            height: "100%",
            maxHeight: "100%",
            [theme.breakpoints.up("lg")]: {},
        },
        title: {
            fontWeight: "bold",
        },
        chart: {
            width: "100%",
        },
        legendBox: {
            flex: 1,
            display: "flex",
            alignItems: "flex-end",
        },
        legendFlex: {
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "space-around",
            flex: 1,
        },
        legendItem: {
            "& p": {
                padding: 0,
                margin: 0,
                "& span": {
                    display: "inline-block",
                    width: "15px",
                    height: "15px",
                    margin: "0 5px -2px 0",
                },
            },
        },
        legendV1: {
            backgroundColor: theme.palette.border[400],
        },
        legendShould: {
            backgroundColor: theme.palette.primary[300],
        },
        legendV2: {
            backgroundColor: theme.palette.border[200],
        },
        skeletonBox: {
            "& span": {
                width: "100%",
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
                borderRadius: theme.shape.borderRadius,
            },
        },
    }),
);

interface IChartsProps {}

const Charts: React.FC<IChartsProps> = () => {
    const classes = useStyles();
    const theme = useTheme();
    const panelRepresent = usePanelRepresent();
    const dispatch = useDispatch<DispatchAction>();
    const filters = useFilters();
    const versionsToCompare = useModelVariantIds();

    const colors = panelRepresent
        ? [theme.palette.border[400], theme.palette.primary[300], theme.palette.border[200]]
        : [theme.palette.border[400], theme.palette.border[200]];

    // translations
    const intl = useIntl();
    const transLoadingDataError = intl.formatMessage({ ...globalMessages.loadingDataError });

    const { data, loading, error } = useCompareVersionsQuery({
        skip: versionsToCompare.length < 2,
        variables: {
            projectFileVersionViewables: versionsToCompare,
            filters: filters,
            externalSystemCode: ExternalSystem.Forge,
        },
    });
    const categories = data && data?.compareGroups?.categories?.filter(category => category?.type === "NORMAL");

    if (error) {
        dispatch(
            onError({
                errorMessage: transLoadingDataError,
            }),
        );
    }

    const getComparedValue = (attribute: ComparedAttribute, comparerValueCode: string): ComparerValue => {
        return attribute.comparedValues?.find(cv => cv?.code === comparerValueCode) as ComparerValue;
    };

    const transformCategoryToChartData = (category: any) => {
        let data: any = [];

        category?.attributes?.map(attr => {
            let obj = {
                property: attr?.name,
                model1: parseInt(getComparedValue(attr, versionsToCompare[0].toString()).value as string), // todo: indexing better
                should: parseInt(getComparedValue(attr, "soll").value as string),
                model2: parseInt(getComparedValue(attr, versionsToCompare[1].toString()).value as string),
            };
            data.push(obj);
        });

        return data.reverse();
    };

    return (
        <Box className={classes.root}>
            <Paper variant="outlined" elevation={0} className={classes.paper}>
                {(loading || error) && (
                    <Box className={classes.skeletonBox}>
                        <Skeleton variant="rect" height={100} />
                        <Skeleton variant="rect" height={100} />
                    </Box>
                )}

                {categories &&
                    !loading &&
                    categories?.map((category, index) => {
                        // todo: better skip
                        if (category?.name === "Kosten") {
                            return null;
                        }
                        const chartData = transformCategoryToChartData(category);
                        return (
                            <React.Fragment key={index}>
                                <Typography variant="inherit" component="p" className={classes.title}>
                                    {category?.name}
                                </Typography>

                                <Box
                                    className={classes.chart}
                                    style={{
                                        height: theme.spacing(category!.attributes!.length * 5.2),
                                        maxHeight: theme.spacing(category!.attributes!.length * 5.2),
                                    }}
                                >
                                    <ResponsiveBar
                                        data={chartData}
                                        keys={panelRepresent ? ["model1", "should", "model2"] : ["model1", "model2"]}
                                        indexBy="property"
                                        margin={{ top: 10, right: 5, bottom: 10, left: 55 }}
                                        padding={0.3}
                                        groupMode="grouped"
                                        layout="horizontal"
                                        colors={colors}
                                        borderWidth={0}
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={null}
                                        axisLeft={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: "",
                                            legendPosition: "middle",
                                            legendOffset: -40,
                                        }}
                                        enableGridY={false}
                                        enableLabel={false}
                                        labelSkipWidth={12}
                                        labelSkipHeight={12}
                                        labelTextColor={{
                                            from: "color",
                                            modifiers: [["darker", 1.6]],
                                        }}
                                        legends={[]}
                                        animate={true}
                                        tooltip={({ indexValue, value }) => (
                                            <div>
                                                {indexValue}: <strong>{value}</strong>
                                            </div>
                                        )}
                                        motionStiffness={90}
                                        motionDamping={15}
                                    />
                                </Box>
                            </React.Fragment>
                        );
                    })}

                <Box className={classes.legendBox}>
                    <Box className={classes.legendFlex}>
                        <Box className={classes.legendItem}>
                            <p>
                                <span className={classes.legendV1} />
                                Version 1
                            </p>
                        </Box>
                        {panelRepresent && (
                            <Box className={classes.legendItem}>
                                <p>
                                    <span className={classes.legendShould} />
                                    Soll
                                </p>
                            </Box>
                        )}
                        <Box className={classes.legendItem}>
                            <p>
                                <span className={classes.legendV2} />
                                Version 2
                            </p>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};

export default Charts;

import {
    ExtensionID as SyncViewNavigationExtensionID,
    ICameraPosition,
} from "./Viewing.Extensions.SyncViewNavigationExtension";
import { Vector3 } from "three";

let viewers: any[] = [];
let disabledViewer = null;
let lastAppliedPosition: ICameraPosition;
let activated: boolean = false;

export const registerViewer = viewer => {
    viewers.push(viewer);
    registerViewerChange(viewer);
};
export const unRegisterViewer = viewer => {
    viewers = viewers.filter(v => v === viewer);
    unregisterViewerChange(viewer);
};

export const activateSyncViewNavigation = (enable: boolean) => {
    activated = enable;
};

const registerViewerChange = viewer => {
    viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, e => handleCameraChanged(e, viewer));
};

const unregisterViewerChange = viewer => {
    viewer.removeEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, e => handleCameraChanged(e, viewer));
};

const handleCameraChanged = (e, viewer) => {
    if (!activated) {
        return;
    }
    if (viewer !== disabledViewer) {
        const ext = viewer.getExtension(SyncViewNavigationExtensionID);
        if (ext) {
            // @ts-ignore
            const nav = ext.getNavigation();
            applyCamera(viewer, nav);
        }
    }
};

const disableViewerChange = viewer => {
    disabledViewer = viewer;
};
const enableViewerChange = () => {
    disabledViewer = null;
};
const hasVectorChanged = (vec1: Vector3, vec2: Vector3, pow: number = -15) => {
    return vec1.distanceTo(vec2) > Math.pow(10, pow);
};

const hasPositionChanged = (position: ICameraPosition) => {
    if (!lastAppliedPosition) {
        return true;
    }

    return (
        hasVectorChanged(position.up, lastAppliedPosition.up) ||
        hasVectorChanged(position.target, lastAppliedPosition.target) ||
        hasVectorChanged(position.position, lastAppliedPosition.position)
    );
};

const applyCamera = (viewer: any, position: ICameraPosition) => {
    if (!activated || !hasPositionChanged(position)) {
        return;
    }
    lastAppliedPosition = position;
    viewers.forEach(v => {
        if (v !== viewer) {
            console.log(`applying camera ${JSON.stringify(position)} to viewer ${viewer.model.id}`);
            const ext = v.getExtension(SyncViewNavigationExtensionID);
            if (ext) {
                disableViewerChange(v);
                ext.setNavigation(position);
                enableViewerChange();
            }
        }
    });
};

import { defineMessages } from "react-intl";

const globalMessages = defineMessages({
    loadingDataError: {
        id: "components__app__loading-data-error",
        defaultMessage: "Loading data error. Please try again later.",
    },
});

export default globalMessages;

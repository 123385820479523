export enum IIssueTab {
    List = 0,
    Create = 1,
    Edit = 2,
}

export interface IIssueState {
    type: number | null;
    status: number | null;
    title: string;
    assignTo: number | null;
    dueDate: Date | null;
    location: string;
    locationDetails: string;
    owner: number | null;
    rootCause: number | null;
    description: string;
}

export enum IIssueStatusType {
    Open = 0,
    Draft = 1,
    Answered = 2,
    Closed = 3,
    Void = 4,
}

export type IIssueStatus = {
    id: number;
    type: IIssueStatusType;
    name: string;
};

export type IIssueSelect = {
    id: number;
    name: string;
    description?: string;
    isCategory?: boolean;
};

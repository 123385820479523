import React from "react";
import { AttributeType, DataType } from "../../graphql/generated/graphql";
import numbro from "numbro";

interface IValueFormatterProps {
    value: any; // todo: probably string
    attributeType: AttributeType;
}

const numbroFormat: numbro.Format = {
    thousandSeparated: true,
    mantissa: 2,
    trimMantissa: true,
};

const FormatedValue: React.FC<IValueFormatterProps> = (props: IValueFormatterProps) => {
    const getStringRepresentation = (value: any, attributeType: AttributeType) => {
        let numberValue;
        if (attributeType == null) {
            return value;
        }
        if (value == null) {
            return null;
        }
        switch (attributeType.dataType) {
            case DataType.Double:
                numberValue = parseFloat(value as string);
            case DataType.Int:
                numberValue = parseInt(value as string);
                return numbro(numberValue)
                    .format(numbroFormat)
                    .replace(",", " "); //change thousands separator

            default:
                return value;
        }
    };

    const getValueWithUnit = (value: string, attributeType: AttributeType) => {
        if (attributeType == null) {
            return value;
        }
        if (value == null) {
            return "-";
        }
        const unit = normalizeUnit(attributeType.unit as string);
        // todo: better detection, add some Mask to attribute type, to suppoort prefix/suffix, etc
        const unitSeparator = unit === "%" ? "" : " ";
        const valueWithUnit = `${value}${unitSeparator}${unit}`;
        return valueWithUnit.trim();
    };

    const normalizeUnit = (unit: string): string => {
        const matchUnit = unit.match(/^(\w)+(\^)?([2,3])$/);

        if (matchUnit) {
            return `${matchUnit[1]}${matchUnit[3] === "2" ? "²" : "³"}`;
        } else {
            return unit;
        }
    };

    let value = getStringRepresentation(props.value, props.attributeType);
    value = getValueWithUnit(value, props.attributeType);

    return <span>{value}</span>;
};

export default FormatedValue;

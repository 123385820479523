import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import clsx from "clsx";
import "date-fns";
import React, { useEffect } from "react";
import { Model } from "../../redux/dashboard/reducer";
import { issueAssignTo } from "./data";
import { useIssuesBoxStyles } from "./IssuesBox";
import Status from "./Status";
import { IIssueState, IIssueTab } from "./type";
import { useIssuables } from "../../redux/dashboard";
import { Issue, useGetIssuesForProjectFileVersionLazyQuery } from "../../graphql/generated/graphql";
import { Skeleton } from "@material-ui/lab";

interface IListIssueProps {
    versionId: number;
    handleChangeTab: (tab: IIssueTab) => void;
}

const ListIssue: React.FC<IListIssueProps> = (props: IListIssueProps) => {
    const classes = useIssuesBoxStyles();
    const { versionId, handleChangeTab } = props;

    const issuables = useIssuables();

    const [
        getIssuesForProjectQuery,
        { data: projectIssuesData, loading: projectIssuesLoading, error: projectIssuesError },
    ] = useGetIssuesForProjectFileVersionLazyQuery({
        fetchPolicy: "network-only", // disable GraphQL query caching
    });

    useEffect(() => {
        getIssuesForProjectQuery({
            variables: {
                projectFileVersionId: versionId as number,
            },
        });
    }, [handleChangeTab]);

    useEffect(() => {
        issuables.forEach(issuable => {
            issuable.renderIssues(projectIssuesData?.issues as Issue[]);
        });
        return () => {
            issuables.forEach(issuable => {
                issuable.renderIssues([]);
            });
        };
    }, [issuables, projectIssuesData, projectIssuesData?.issues]);

    return (
        <>
            <DialogTitle id="issues-dialog-title" className={classes.title}>
                Aufgaben
                {/* IssuesBox */}
            </DialogTitle>
            <DialogContent className={clsx(classes.content, classes.contentList)}>
                {projectIssuesLoading || projectIssuesError ? (
                    <>
                        {[1, 2, 3].map(index => (
                            <Box className={classes.skeletonBoxIssue} key={index}>
                                <Box display="flex" justifyContent="space-between">
                                    <Skeleton variant="rect" height={18} width="30%" />
                                    <Skeleton variant="rect" height={18} width="30%" />
                                </Box>
                                <Skeleton variant="rect" height={22} />
                                <Skeleton variant="rect" height={15} width="70%" />
                                <Skeleton variant="rect" height={15} width="70%" />
                            </Box>
                        ))}
                    </>
                ) : (
                    <Box>
                        {projectIssuesData?.issues?.map((issue, index: number) => {
                            const dueDate = new Date(issue?.dueDate);

                            return (
                                <Box key={index} className={classes.boxIssue}>
                                    <Box className={classes.boxStatus}>
                                        <Status issueStatus={issue?.status} />
                                        <p>
                                            {issue?.dueDate !== null ? (
                                                <span className={classes.highlight}>
                                                    {dueDate.toLocaleDateString("en")}
                                                </span>
                                            ) : (
                                                <>Unspezifiziert</> // Unspecified
                                            )}
                                        </p>
                                    </Box>
                                    <Box>
                                        <Typography variant="h2">
                                            <strong>#{issue?.code}</strong> - {issue?.name}
                                        </Typography>
                                        <p>
                                            Zuweisen zu: {/* Assigned to */}
                                            <>Unspezifiziert</>
                                            {/*{issue.assignTo ? (*/}
                                            {/*    issueAssignTo?.[issue.assignTo]?.name*/}
                                            {/*) : (*/}
                                            {/*    <>Unspezifiziert</>*/}
                                            {/*) // Unspecified*/}
                                            {/*}*/}
                                        </p>
                                        <p>
                                            Standort: {/* Location */}
                                            {issue?.locationDescription ? (
                                                issue?.locationDescription
                                            ) : (
                                                <>Unspezifiziert</>
                                            ) // Unspecified
                                            }
                                        </p>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                )}
            </DialogContent>
            <DialogActions className={clsx(classes.actions, classes.actionsList)}>
                <Button variant="contained" color="primary" onClick={() => handleChangeTab(IIssueTab.Create)}>
                    Aufgabe erstellen
                    {/* Create issue */}
                </Button>
            </DialogActions>
        </>
    );
};

export default ListIssue;

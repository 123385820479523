import { Box, createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        status: {
            padding: "2px 10px",
            fontSize: ".8rem",
            borderRadius: "50px",
            textTransform: "uppercase",
            color: theme.palette.white.main,
        },
    }),
);

interface IStatusProps {
    issueStatus: any;
}

const Status: React.FC<IStatusProps> = (props: IStatusProps) => {
    const classes = useStyles();
    const { issueStatus } = props;

    return (
        <Box className={classes.root}>
            <span
                className={classes.status}
                style={{
                    background: issueStatus?.highlighColor,
                }}
            >
                {issueStatus?.name}
            </span>
        </Box>
    );
};

export default Status;

import { createAction, DispatchAction } from "@iolabs/redux-utils";
import { IIssueState } from "../../components/Issues/type";
import { Filter, IIssuable } from "./reducer";
import { ProjectFileVersion } from "../../graphql/generated/graphql";

export enum ActionTypes {
    OnIssueCreate = "dashboard/ON_ISSUE_CREATE",
    OnPanelSwitch = "dashboard/ON_PANEL_SWITCH",
    OnFilterDefault = "dashboard/ON_FILTER_DEFAULT",
    OnFilterChange = "dashboard/ON_FILTER_CHANGE",
    OnCompareItemSelected = "dashboard/ON_COMPARE_ITEM_SELECT",
    OnViewableSelected = "dashboard/ON_VIEWABLE_SELECT",
    OnCreateIssue = "dashboard/ON_CREATE_ISSUE",
    OnRegisterIssuable = "dashboard/ON_REGISTER_ISSUABLE",
    OnUnregisterIssuable = "dashboard/ON_UNREGISTER_ISSUABLE",
}

export type IssueCreateOptions = {
    id: number;
    issue: IIssueState;
};

export type FilterDefaultOptions = {
    defaultFilter: Filter[];
};

export type FilterChangeOptions = {
    filters?: { [key: number]: string[] };
    visibleIds: { [key: number]: string[] };
};

export type CompareItemSelectOptions = {
    selectedIds: { [key: number]: string[] };
};

export type VersionSelectOptions = {
    index: number;
    viewable: ProjectFileVersion;
};

export type PanelSwitchOptions = {
    represent?: boolean;
    surrounding?: boolean;
    isolate?: boolean;
    syncView?: boolean;
    overlap?: boolean;
    turntable?: boolean;
    speed?: number;
};

export type RegisterIssuableOptions = {
    issuable: IIssuable;
};
export type UnregisterIssuableOptions = {
    id: string;
};

export type CreateIssueOptions = {
    issue: IIssueState;
};

const Actions = {
    onIssueCreate: (options: IssueCreateOptions) => createAction(ActionTypes.OnIssueCreate, options),
    onPanelSwitch: (options: PanelSwitchOptions) => createAction(ActionTypes.OnPanelSwitch, options),
    onFilterDefault: (options: FilterDefaultOptions) => createAction(ActionTypes.OnFilterDefault, options),
    onFilterChange: (options: FilterChangeOptions) => createAction(ActionTypes.OnFilterChange, options),
    onCompareItemSelect: (options: CompareItemSelectOptions) =>
        createAction(ActionTypes.OnCompareItemSelected, options),
    onViewableSelect: (options: VersionSelectOptions) => createAction(ActionTypes.OnViewableSelected, options),
    onRegisterIssuable: (options: RegisterIssuableOptions) => createAction(ActionTypes.OnRegisterIssuable, options),
    onUnregisterIssuable: (options: UnregisterIssuableOptions) =>
        createAction(ActionTypes.OnUnregisterIssuable, options),
    onCreateIssue: (options: CreateIssueOptions) => createAction(ActionTypes.OnCreateIssue, options),
};

export function onIssueCreate(options: IssueCreateOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onIssueCreate(options));
    };
}

export function onPanelSwitch(options: PanelSwitchOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onPanelSwitch(options));
    };
}

export function onFilterDefault(options: FilterDefaultOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onFilterDefault(options));
    };
}

export function onFilterChange(options: FilterChangeOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onFilterChange(options));
    };
}
export function onCompareItemSelect(options: CompareItemSelectOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onCompareItemSelect(options));
    };
}
export function onViewableSelect(options: VersionSelectOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onViewableSelect(options));
    };
}
export function onRegisterIssuable(options: RegisterIssuableOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onRegisterIssuable(options));
    };
}

export function onUnregisterIssuable(options: UnregisterIssuableOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onUnregisterIssuable(options));
    };
}

export function onCreateIssue(options: CreateIssueOptions): DispatchAction {
    return async dispatch => {
        dispatch(Actions.onCreateIssue(options));
    };
}

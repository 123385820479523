import { DispatchAction, onError } from "@iolabs/redux-utils";
import { Box, createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import {
    Project,
    ProjectFile,
    ProjectFileVersion,
    ProjectFileVersionViewable,
    useGetProjectQuery,
} from "../../graphql/generated/graphql";
import globalMessages from "../App/messages";
import Viewer from "../Viewer/Viewer";
import VersionSelector from "./VersionSelector";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            height: "100%",
            position: "relative",
        },
        versionSelector: {
            pointerEvents: "none",
            position: "absolute",
            zIndex: 10,
            top: 0,
            left: 0,
            right: 0,
        },
    }),
);

interface IProjectModelViewer extends React.HTMLAttributes<HTMLElement> {
    modelId: number;
    count: number;
    onVersionSelected?: (projectFileVersion: ProjectFileVersion) => void;
    onViewableSelected?: (projectFileVersionViewable: ProjectFileVersionViewable) => void;
    selectionIds?: string[];
    showOnlyIds?: string[];
}

const ProjectModelViewer: React.FC<IProjectModelViewer> = (props: IProjectModelViewer) => {
    const { modelId, count, onVersionSelected, onViewableSelected, selectionIds, showOnlyIds } = props;
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();

    const [version, setVersion] = useState<ProjectFileVersion>();
    const [viewable, setViewable] = useState<ProjectFileVersionViewable>();

    const intl = useIntl();
    const transLoadingDataError = intl.formatMessage({ ...globalMessages.loadingDataError });
    const { data, loading, error } = useGetProjectQuery({
        variables: {
            code: "01", // todo how to get project code?
        },
    });

    if (error) {
        dispatch(
            onError({
                errorMessage: transLoadingDataError,
            }),
        );
    }

    const project: Project = data?.projects?.find(pr => pr?.code === "01") as Project;

    const models: ProjectFile[] = project?.projectFiles?.filter(
        pf => pf?.fileType?.code === "GeometryFile",
    ) as ProjectFile[];

    const selectVersion = (version: ProjectFileVersion) => {
        setVersion(version);
        if (onVersionSelected) {
            onVersionSelected(version);
        }
    };

    const selectViewable = (viewable: ProjectFileVersionViewable) => {
        setViewable(viewable);
        if (onViewableSelected) {
            onViewableSelected(viewable);
        }
    };

    const urn = version?.fileVersionsInExternalSystem?.find(fves => fves?.externalSystem?.code === "forge")
        ?.externalId as string;

    const viewableGuid = viewable?.viewableInExternalSystem?.find(fves => fves?.externalSystem?.code === "forge")
        ?.externalId as string;

    return (
        <Box className={classes.root}>
            <VersionSelector
                modelId={modelId}
                count={count}
                models={models}
                onVersionSelected={selectVersion}
                onViewableSelected={selectViewable}
                hideViewableSelector={true}
            />
            {version && urn && (
                <Viewer
                    projectId={project.id as number}
                    projectFileVersionId={version.id as number}
                    viewableGuid={viewableGuid}
                    disableFetchLatest={true}
                    urn={urn}
                    selectionIds={selectionIds}
                    showOnlyIds={showOnlyIds}
                />
            )}
        </Box>
    );
};

export default ProjectModelViewer;
